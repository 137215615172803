import appointments from './show/appointments/appointments.component';
import commitments from './show/commitments/commitments.component';
import component from './coaches.component';
import list from './list/list.component';
import listItem from './list/item/item.component';
import outstandingNeeds from './show/outstandingSpecial/needs.component';
import outstandingPledges from './show/outstandingPledges/pledges.component';
import progress from './progress/progress.component';
import show from './show/show.component';
import supportSummary from './show/supportSummary/supportSummary.component';
import tags from './show/tags/tags.component';
import weeklyReport from './show/weeklyReport/weeklyReport.component';

export default angular.module('mpdx.coaches', [
    appointments,
    commitments,
    component,
    list,
    listItem,
    outstandingNeeds,
    outstandingPledges,
    progress,
    show,
    supportSummary,
    tags,
    weeklyReport,
]).name;
