import alerts, { AlertsService } from '../../../../../common/alerts/alerts.service';
import exportContacts, { ExportContactsService } from '../export.service';

export class MailingPdfController {
    labelTypes: { id: string; display_name: string }[];
    labelType: string;
    sort: string;
    sorts: { id: string; display_name: string }[];
    constructor(
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private alerts: AlertsService,
        private exportContacts: ExportContactsService,
        private filters: any,
    ) {
        this.labelType = 'Avery5160';
        this.labelTypes = [
            {
                id: 'Avery5160',
                display_name: gettextCatalog.getString('Avery 5160'),
            },
            {
                id: 'Avery7160',
                display_name: gettextCatalog.getString('Avery 7160'),
            },
        ];
        this.sort = 'name';
        this.sorts = [
            {
                id: 'name',
                display_name: gettextCatalog.getString('Contact Name'),
            },
            {
                id: 'zip',
                display_name: gettextCatalog.getString('Zip'),
            },
        ];
    }
    save(): ng.IPromise<void> {
        return this.exportContacts
            .create({
                filters: this.filters,
                format: 'pdf',
                labelType: this.labelType,
                sort: this.sort,
            })
            .then(() => {
                this.alerts.addAlert(this.gettextCatalog.getString('Contacts exported successfully as a PDF file'));
                this.$scope.$hide();
            });
    }
}

export default angular
    .module('mpdx.contacts.bulkActions.contact.export.mailingPdf.controller', ['gettext', alerts, exportContacts])
    .controller('contactsBulkActionsContactExportMailingPdfController', MailingPdfController).name;
