import 'angular-block-ui';
import 'angular-gettext';
import { reject } from 'lodash/fp';
import fixCommitmentInfo, { FixCommitmentInfoService } from './commitment.service';
import modal, { ModalService } from '../../../common/modal/modal.service';
import tools, { ToolsService } from '../../tools.service';

class CommitmentInfoController {
    blockUI: IBlockUIService;
    watcher: any;
    watcher2: any;
    appName: string = process.env.APP_NAME;
    constructor(
        private $rootScope: ng.IRootScopeService,
        blockUI: IBlockUIService,
        private fixCommitmentInfo: FixCommitmentInfoService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private modal: ModalService,
        private tools: ToolsService,
    ) {
        this.blockUI = blockUI.instances.get('fix-commitment-info');
    }
    $onInit() {
        this.watcher = this.$rootScope.$on('contactHidden', (e, id) => {
            this.fixCommitmentInfo.data = reject({ id: id }, this.fixCommitmentInfo.data);
        });
        this.watcher2 = this.$rootScope.$on('accountListUpdated', () => {
            this.load();
        });
    }
    $onDestroy() {
        this.watcher();
        this.watcher2();
    }
    save(): ng.IPromise<void> {
        const message = this.gettextCatalog.getString(
            `You are updating all visible contacts to the visible Commitment Info.
            Are you sure you want to do this?`,
        );
        return this.modal.confirm(message).then(() => {
            this.blockUI.start();
            return this.fixCommitmentInfo.bulkSave().then(() => {
                this.tools.getAnalytics(true);
                this.blockUI.reset();
            });
        });
    }
    load(page = null): ng.IPromise<any> {
        return this.fixCommitmentInfo.load(true, page);
    }
}

const FixCommitmentInfo = {
    controller: CommitmentInfoController,
    template: require('./commitment.html'),
};

export default angular
    .module('mpdx.tools.fixCommitmentInfo.component', ['gettext', 'blockUI', modal, fixCommitmentInfo, tools])
    .component('fixCommitmentInfo', FixCommitmentInfo).name;
