import { defaultTo, isNil } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import accounts, { AccountsService } from '../../../common/accounts/accounts.service';
import contacts, { ContactsService } from '../../contacts.service';
import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';
import tasks, { TasksService } from '../../../tasks/tasks.service';
import uiRouter from '@uirouter/angularjs';

class ContactInfoController {
    address: boolean;
    contact: any;
    content: boolean;
    header: boolean;
    onSave: any;
    person: boolean;
    status: string;
    constructor(
        private $state: StateService,
        private accounts: AccountsService, // used in view
        private contacts: ContactsService,
        private serverConstants: ServerConstantsService, // used in view
        private tasks: TasksService, // used in view
    ) {}
    $onInit(): void {
        this.status = angular.copy(this.contact.status);
    }
    $onChanges(obj) {
        if (obj.contact) {
            if (parseInt(obj.contact.currentValue.pledge_frequency) > 0) {
                this.contact.pledge_frequency = obj.contact.currentValue.pledge_frequency + '.0';
            }
        }
    }
    saveWithEmptyCheck(property) {
        this.contact[property] = defaultTo('', this.contact[property]);
        this.save();
    }
    hideContact(): ng.IPromise<void> {
        return this.contacts.hideContact(this.contacts.current).then(() => {
            this.$state.go('contacts');
        });
    }
    save(): ng.IPromise<void> {
        if (this.checkInvalidCommitmentInfo()) {
            this.contacts.openShowInfoFixPledgeModal(this.contact);
        }
        return this.onSave().then(() => {
            this.status = this.contact.status;
        });
    }
    private checkInvalidCommitmentInfo(): boolean {
        return (
            this.status === 'Partner - Financial' &&
            this.contact.status !== 'Partner - Financial' &&
            (this.isPresent(this.contact.pledge_amount) || this.isPresent(this.contact.pledge_frequency))
        );
    }
    private isPresent(value: any): boolean {
        return !isNil(value) && value !== '' && value !== 0;
    }
    star(): ng.IPromise<any> {
        return this.contacts.star(this.contacts.current).then((data) => {
            this.contacts.current.starred_at = data.starred_at;
        });
    }
}
const Info = {
    controller: ContactInfoController,
    template: require('./info.html'),
    bindings: {
        contact: '=',
        onSave: '&',
        content: '@',
        header: '@',
        person: '@',
        address: '@',
    },
};

export default angular
    .module('mpdx.contacts.show.info.component', [uiRouter, accounts, contacts, serverConstants, tasks])
    .component('contactInfo', Info).name;
