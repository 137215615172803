import { StateParams, StateService } from '@uirouter/core';
import contacts, { ContactsService } from '../contacts.service';
import contactsTags, { ContactsTagsService } from '../sidebar/filter/tags/tags.service';
import serverConstants, { ServerConstantsService } from '../../common/serverConstants/serverConstants.service';
import uiRouter from '@uirouter/angularjs';

export class DrawerController {
    loading: boolean;
    watcher0: () => void;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private $stateParams: StateParams,
        private contacts: ContactsService,
        private contactsTags: ContactsTagsService, // used in view,
        private serverConstants: ServerConstantsService,
    ) {}
    $onInit() {
        this.watcher0 = this.$rootScope.$on('accountListUpdated', () => {
            this.$state.go('^');
        });
        this.load();
    }
    $onDestroy() {
        this.watcher0();
    }
    load() {
        this.loading = true;
        return this.serverConstants
            .load([
                'assignable_likely_to_give_hashes',
                'assignable_send_newsletter_hashes',
                'locales',
                'pledge_currencies',
                'pledge_frequency_hashes',
                'status_hashes',
            ])
            .then(() => {
                return this.contacts.get(this.$stateParams.contactId).then((data) => {
                    this.loading = false;
                    this.contacts.current = data;
                    this.contacts.initialState = angular.copy(data);
                    return data;
                });
            });
    }
}

const Drawer = {
    controller: DrawerController,
    template: require('./drawer.html'),
};

export default angular
    .module('mpdx.contacts.drawer.component', [uiRouter, contacts, contactsTags, serverConstants])
    .component('contactsDrawer', Drawer).name;
