import { get } from 'lodash/fp';
import api, { ApiService } from '../../../common/api/api.service';
import modal, { ModalService } from '../../../common/modal/modal.service';

export class ItemController {
    ref: any;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private modal: ModalService,
    ) {}
    appealReceived(): number {
        if (get('primary_appeal', this.ref.account_list)) {
            return (
                parseFloat(this.ref.account_list.primary_appeal.pledges_amount_processed) +
                parseFloat(this.ref.account_list.primary_appeal.pledges_amount_received_not_processed)
            );
        }
        return 0;
    }
    appealReceivedPercentage(): number {
        if (get('primary_appeal', this.ref.account_list)) {
            return (this.appealReceived() / parseFloat(this.ref.account_list.primary_appeal.amount)) * 100;
        }
        return 0;
    }
    appealCommitted(): number {
        if (get('primary_appeal', this.ref.account_list)) {
            return (
                parseFloat(this.ref.account_list.primary_appeal.pledges_amount_processed) +
                parseFloat(this.ref.account_list.primary_appeal.pledges_amount_received_not_processed) +
                parseFloat(this.ref.account_list.primary_appeal.pledges_amount_not_received_not_processed)
            );
        }
        return 0;
    }
    appealCommittedPercentage(): number {
        if (get('primary_appeal', this.ref.account_list)) {
            return (this.appealCommitted() / parseFloat(this.ref.account_list.primary_appeal.amount)) * 100;
        }
        return 0;
    }
    delete(): ng.IPromise<void> {
        const confirmMessage = this.gettextCatalog.getString(
            'Are you sure you want to remove your access to this account list? ' +
                'You can always request a new coaching invite from the connected user(s) if you need access again.',
        );
        const successMessage = this.gettextCatalog.getString('{{app_name}} removed your access successfully', {
            app_name: process.env.APP_NAME,
        });
        const errorMessage = this.gettextCatalog.getString("{{app_name}} couldn't remove your access", {
            app_name: process.env.APP_NAME,
        });
        return this.modal.confirm(confirmMessage).then(() => {
            return this.api
                .delete({
                    url: `user/account_list_coaches/${this.ref.id}`,
                    type: 'account_list_coaches',
                    successMessage,
                    errorMessage,
                })
                .then(() => {
                    this.$rootScope.$emit('accountListCoachDeleted', this.ref.id);
                });
        });
    }
}

const Item = {
    controller: ItemController,
    template: require('./item.html'),
    bindings: {
        ref: '<',
    },
};

export default angular
    .module('mpdx.coaches.list.item.component', ['gettext', api, modal])
    .component('coachesListItem', Item).name;
