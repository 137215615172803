import accounts, { AccountsService } from '../../../common/accounts/accounts.service';
import financialAccounts, {
    FinancialAccountsService,
} from '../../../common/financialAccounts/financialAccounts.service';

export class ListController {
    loading: boolean;
    constructor(
        private accounts: AccountsService, // used in view
        private financialAccounts: FinancialAccountsService,
    ) {}
    $onInit(): void {
        this.load();
    }
    load(reset = false): ng.IPromise<any> {
        this.loading = true;
        return this.financialAccounts.load(reset).then(() => {
            this.loading = false;
        });
    }
    onToggle(financialAccount): void {
        financialAccount.active = !financialAccount.active;
        this.financialAccounts.save(financialAccount);
    }
}

const List = {
    controller: ListController,
    template: require('./list.html'),
};

export default angular
    .module('mpdx.reports.financialAccounts.list.component', [accounts, financialAccounts])
    .component('financialAccountsList', List).name;
