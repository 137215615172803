import accountBalance from './accountBalance/accountBalance.component';
import appeals from './appeals/appeals.component';
import care from './care/care.module';
import commitments from './commitments/commitments.component';
import component from './home.component';
import exportContacts from './export/export.controller';
import monthlyGoal from './monthlyGoal/monthlyGoal.component';
import progress from '../coaches/show/progress/progress.module';
import referrals from './referrals/referrals.module';
import tasks from './tasks/tasks.component';
import weeklyActivity from './weeklyActivity/weeklyActivity.component';
import welcome from './welcome/welcome.component';

export default angular.module('mpdx.home', [
    accountBalance,
    appeals,
    care,
    commitments,
    component,
    exportContacts,
    monthlyGoal,
    progress,
    referrals,
    tasks,
    weeklyActivity,
    welcome,
]).name;
