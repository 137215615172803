import pagination from '../../pagination/pagination';

class SubMenuController {
    pagination: pagination;
    constructor() {
        this.pagination = pagination;
    }
}

const SubMenu: ng.IComponentOptions = {
    template: require('./menu.html'),
    controller: SubMenuController,
    bindings: {
        loading: '<',
        meta: '<',
        selected: '<',
        userOption: '@',
        onChange: '&',
        selectedCount: '<',
        onDeselect: '&',
    },
};

export default angular.module('common.subMenu.component', []).component('paginationSubMenu', SubMenu).name;
