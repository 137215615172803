import accounts, { AccountsService } from '../../common/accounts/accounts.service';
import api, { ApiService } from '../../common/api/api.service';
import users, { UsersService } from '../../common/users/users.service';

export class AccountListsController {
    showAllTags: boolean;
    constructor(
        private accounts: AccountsService, // used in view
        private api: ApiService, // used in view
        private users: UsersService, // used in view
    ) {
        this.showAllTags = false;
    }
}

const AccountLists = {
    controller: AccountListsController,
    template: require('./accountLists.html'),
};

export default angular
    .module('mpdx.menu.accountLists.component', [accounts, api, users])
    .component('menuAccountLists', AccountLists).name;
