const progressElectronicContacts = {
    template: require('./electronicContacts.html'),
    bindings: {
        analytics: '<',
        endDate: '<',
        inCoaching: '<',
        startDate: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.progress.electronicContacts', [])
    .component('coachesShowProgressElectronicContacts', progressElectronicContacts).name;
