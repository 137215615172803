import 'angular-gettext';
import accounts, { AccountsService } from '../common/accounts/accounts.service';
import help, { HelpService } from '../common/help/help.service';

class PreferencesController {
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService,
        help: HelpService,
    ) {
        this.gettextCatalog = gettextCatalog;

        this.accounts = accounts;

        if (help.variables().HS_SETTINGS_PREFERENCES_SUGGESTIONS?.length) {
            const suggestionsArray = [];
            help.variables().HS_SETTINGS_PREFERENCES_SUGGESTIONS.forEach((i) => {
                suggestionsArray.push(this.gettextCatalog.getString(i));
            });
            help.suggest(suggestionsArray);
        }
    }
}

const Preferences = {
    controller: PreferencesController,
    template: require('./preferences.html'),
};

export default angular
    .module('mpdx.preferences.component', ['gettext', accounts, help])
    .component('preferences', Preferences).name;
