import { replace, startsWith } from 'lodash/fp';

function accountingCurrencyFilter($filter) {
    return function (amount, currencySymbol, precision) {
        const currency = $filter('currency');

        return startsWith('-', amount)
            ? `(${replace('-', '', currency(amount, currencySymbol, precision))})`
            : currency(amount, currencySymbol, precision);
    };
}

export default angular
    .module('mpdx.common.accountCurrency.filter', [])
    .filter('accountingCurrency', accountingCurrencyFilter).name;
