import accounts, { AccountsService } from '../../common/accounts/accounts.service';
import designationAccounts, {
    DesignationAccountsService,
} from '../../common/designationAccounts/designationAccounts.service';
import financialAccounts, { FinancialAccountsService } from '../../common/financialAccounts/financialAccounts.service';

export class DesignationAccountsController {
    converted_total: number;
    loading: boolean;
    constructor(
        private accounts: AccountsService, // used in view
        private financialAccounts: FinancialAccountsService,
        private designationAccounts: DesignationAccountsService,
    ) {}
    onToggle(designationAccount): ng.IPromise<any> {
        designationAccount.active = !designationAccount.active;
        return this.designationAccounts.save(designationAccount);
    }
    activeFinancialAccounts(): boolean {
        return this.financialAccounts.data.some((financialAccount) => financialAccount.active);
    }
}

const Balances = {
    controller: DesignationAccountsController,
    template: require('./designationAccounts.html'),
};

export default angular
    .module('mpdx.reports.designationAccounts.component', [financialAccounts, accounts, designationAccounts])
    .component('designationAccounts', Balances).name;
