import api, { ApiService } from '../../../common/api/api.service';
import contacts, { ContactsService } from '../../contacts.service';

export class NoticesController {
    contact: any;
    duplicateCount?: number;
    constructor(
        private $log: ng.ILogService,
        private api: ApiService,
        private contacts: ContactsService, // used in view
    ) {}
    $onInit(): void {
        this.checkForDuplicates();
    }
    $onChanges(): void {
        this.checkForDuplicates();
    }
    checkForDuplicates(): ng.IPromise<void> {
        return this.api
            .get('contacts/duplicates', {
                filter: {
                    account_list_id: this.api.account_list_id,
                    record_ids: this.contact.id,
                    ignore: false,
                },
                per_page: 2,
            })
            .then((data) => {
                this.duplicateCount = data.length;
                /* istanbul ignore next */
                this.$log.debug('contacts/duplicates', data);
            });
    }
    close(notice: any): void {
        this.duplicateCount = notice === 'duplicate' ? 0 : this.duplicateCount;
    }
}

const Notices: ng.IComponentOptions = {
    template: require('./notices.html'),
    controller: NoticesController,
    bindings: {
        contact: '<',
    },
};

export default angular
    .module('mpdx.contacts.show.notices.component', [api, contacts])
    .component('contactNotices', Notices).name;
