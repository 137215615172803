import { first, get, map, reject } from 'lodash/fp';
import contacts, { ContactsService } from '../../../contacts.service';

export class MergeController {
    winner: any;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private contacts: ContactsService,
        private selectedContacts: any[],
    ) {
        this.winner = get('id', first(selectedContacts));
    }
    save(): ng.IPromise<any> {
        const filtered = reject({ id: this.winner }, this.selectedContacts);
        const winnersAndLosers = map((val) => {
            return {
                winner_id: this.winner,
                loser_id: val.id,
            };
        }, filtered);
        return this.contacts.merge(winnersAndLosers).then(() => {
            this.$rootScope.$emit('contactCreated');
            this.$scope.$hide();
        });
    }
}

export default angular
    .module('mpdx.contacts.bulkActions.contact.merge.controller', [contacts])
    .controller('contactsBulkActionsContactMergeController', MergeController).name;
