import { defaultTo, floor, get, max } from 'lodash/fp';
import accounts, { AccountsService } from '../../../../common/accounts/accounts.service';

export class ProgressbarController {
    appeal: any;
    progress: {
        given: {
            value: number;
            percentage: number;
        };
        received: {
            value: number;
            percentage: number;
        };
        committed: {
            value: number;
            percentage: number;
        };
        max: number;
    };
    constructor(
        private accounts: AccountsService, // used in view
    ) {
        this.progress = {
            given: {
                value: 0,
                percentage: 0,
            },
            received: {
                value: 0,
                percentage: 0,
            },
            committed: {
                value: 0,
                percentage: 0,
            },
            max: 0,
        };
    }
    $onChanges() {
        this.progress.max = parseFloat(max([defaultTo(1, get('amount', this.appeal)), 1]));

        this.progress.given.value = parseFloat(defaultTo(0, get('pledges_amount_processed', this.appeal)));
        this.progress.given.percentage = this.getPercentage(this.progress.given.value);

        this.progress.received.value =
            this.progress.given.value +
            parseFloat(defaultTo(0, get('pledges_amount_received_not_processed', this.appeal)));
        this.progress.received.percentage = this.getPercentage(this.progress.received.value);

        this.progress.committed.value =
            this.progress.received.value +
            parseFloat(defaultTo(0, get('pledges_amount_not_received_not_processed', this.appeal)));
        this.progress.committed.percentage = this.getPercentage(this.progress.committed.value);
    }
    private getPercentage(value): number {
        return floor((value / this.progress.max) * 100);
    }
}

const Progressbar = {
    template: require('./progressbar.html'),
    controller: ProgressbarController,
    bindings: {
        appeal: '<',
    },
};

export default angular
    .module('mpdx.tools.appeals.show.progressbar.component', [accounts])
    .component('appealsProgressbar', Progressbar).name;
