import 'angular-gettext';
import * as moment from 'moment';
import { initial, map, mean, round } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import api, { ApiService } from '../../../common/api/api.service';
import uiRouter from '@uirouter/angularjs';

export class ChartController {
    colors: any[];
    data: any;
    financialAccount: any;
    labels: string[];
    loading: boolean;
    monthlyAverage: number;
    options: any;
    constructor(
        private $state: StateService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
    ) {
        this.colors = [
            {
                backgroundColor: '#fdb800',
            },
        ];
    }
    $onInit(): void {
        this.load();
    }
    load(): ng.IPromise<any> {
        this.loading = true;
        return this.api
            .get('reports/entry_histories', {
                fields: {
                    reports_entry_histories_periods: 'closing_balance,end_date',
                },
                filter: {
                    range: '13m',
                    account_list_id: this.api.account_list_id,
                    financial_account_id: this.financialAccount.id,
                },
            })
            .then((data) => {
                this.loading = false;
                data.pop();
                this.data = [map((item) => -parseFloat(item.closing_balance), data)];
                this.labels = map((item) => moment(item.end_date, 'YYYY-MM-DD').format('MMM YY'), data);
                this.monthlyAverage = round(mean(initial(this.data[0])) * 100) / 100;
                this.options = this.getOptions();
            });
    }
    private getOptions() {
        return {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    label: (tooltipItem) => {
                        const label = this.gettextCatalog.getString('Closing Balance');
                        const value = tooltipItem.yLabel.toLocaleString();
                        return `${label}: ${value}`;
                    },
                },
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        barThickness: 40,
                    },
                ],
                yAxes: [
                    {
                        scaleLabel: {
                            display: true,
                            labelString: `${this.gettextCatalog.getString('Amount')} (${
                                this.financialAccount.currency
                            })`,
                        },
                        ticks: {
                            beginAtZero: true,
                            userCallback: (value) => value.toLocaleString(),
                        },
                    },
                ],
            },
            annotation: {
                annotations: [
                    {
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y-axis-0',
                        value: this.monthlyAverage,
                        borderColor: '#666062',
                        borderWidth: 2,
                    },
                ],
                drawTime: 'beforeDatasetsDraw',
            },
            onClick: (event, legendItem) => this.onClick(event, legendItem),
        };
    }
    private onClick(_event, legendItem) {
        if (legendItem.length === 0) {
            return;
        }
        const startDate = moment(`01 ${legendItem[0]._model.label}`, 'DD MMM YY');
        this.$state.go('reports.financialAccounts.show.entries', {
            financialAccountId: this.financialAccount.id,
            startDate: startDate.locale('en').format('YYYY-MM-DD'),
            endDate: startDate.endOf('month').locale('en').format('YYYY-MM-DD'),
        });
    }
}

const Chart = {
    controller: ChartController,
    template: require('./chart.html'),
    bindings: {
        financialAccount: '<',
    },
};

export default angular
    .module('mpdx.reports.financialAccounts.chart.component', ['gettext', uiRouter, api])
    .component('financialAccountsChart', Chart).name;
