import { defaultTo } from 'lodash/fp';
import alerts, { AlertsService } from '../../../../../common/alerts/alerts.service';
import api, { ApiService } from '../../../../../common/api/api.service';
import contactFilter, { ContactFilterService } from '../../../../sidebar/filter/filter.service';
import exportContacts, { ExportContactsService } from '../export.service';
import joinComma from '../../../../../common/fp/joinComma';
import modal, { ModalService } from '../../../../../common/modal/modal.service';

export class CustomController {
    filters: any;
    params: any;
    constructor(
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private alerts: AlertsService,
        private api: ApiService,
        private contactFilter: ContactFilterService,
        private exportContacts: ExportContactsService,
        private modal: ModalService,
        selectedContactIds: string[],
        filters: any,
    ) {
        this.filters = this.filterParams(filters, selectedContactIds);
    }
    exportMailingCSV(): ng.IPromise<void> {
        return this.exportContacts.create({ filters: this.filters, format: 'csv', mailing: true }).then(() => {
            this.alerts.addAlert(
                this.gettextCatalog.getString('Mailing Addresses for contacts exported successfully as a CSV file'),
            );
            this.$scope.$hide();
        });
    }
    exportMailingPDF(): ng.IPromise<void> {
        this.$scope.$hide();
        return this.modal.open({
            template: require('../mailingPdf/mailingPdf.html'),
            controller: 'contactsBulkActionsContactExportMailingPdfController',
            locals: {
                filters: this.filters,
            },
        });
    }
    exportCSV(): ng.IPromise<void> {
        return this.exportContacts.create({ filters: this.filters, format: 'csv' }).then(() => {
            this.alerts.addAlert(this.gettextCatalog.getString('Contacts exported successfully as a CSV file'));
            this.$scope.$hide();
        });
    }
    exportXLSX(): ng.IPromise<void> {
        return this.exportContacts.create({ filters: this.filters, format: 'xlsx' }).then(() => {
            this.alerts.addAlert(this.gettextCatalog.getString('Contacts exported successfully as a XLSX file'));
            this.$scope.$hide();
        });
    }
    filterParams(filters: any, selectedContactIds: string[]): any {
        filters = defaultTo(this.api.cleanFilters(this.contactFilter.buildFilterParams()), filters);
        if (selectedContactIds && selectedContactIds.length > 0) {
            filters.ids = joinComma(selectedContactIds);
        }
        return filters;
    }
}

export default angular
    .module('mpdx.contacts.bulkActions.contact.export.custom.controller', [
        'gettext',
        alerts,
        api,
        contactFilter,
        exportContacts,
        modal,
    ])
    .controller('contactsBulkActionsContactExportCustomController', CustomController).name;
