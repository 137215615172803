import { each, filter, isUndefined, reduce, reject } from 'lodash/fp';
import { StateParams } from '@uirouter/core';
import api, { ApiService } from '../../../../common/api/api.service';
import appeals, { AppealsService } from '../../appeals.service';
import appealsFlows, { FlowsService, FlowStatus, IFlowsConfig } from '../flows.service';
import contactFilter, { ContactFilterService } from '../../../../contacts/sidebar/filter/filter.service';
import uiRouter from '@uirouter/angularjs';

interface IFlowsColumnSortableOptions {
    containment: string;
    dragStart: (event) => void;
}

export class ColumnController {
    config: IFlowsConfig;
    data: any[];
    sortableOptions: IFlowsColumnSortableOptions;
    listLoadCount: number;
    loading: boolean;
    watcher0: () => void;
    watcher1: () => void;
    watcher2: () => void;
    watcher3: () => void;
    constructor(
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $stateParams: StateParams,
        private api: ApiService,
        private appeals: AppealsService, // used in view
        private appealsFlows: FlowsService,
        private contactFilter: ContactFilterService,
    ) {
        this.data = [];
        this.sortableOptions = {
            containment: '.flow-columns',
            dragStart: (event) => {
                this.appealsFlows.setDraggable(this.config, event.source.itemScope.modelValue);
            },
        };
        this.listLoadCount = 0;
    }
    $onInit(): void {
        this.watcher0 = this.$rootScope.$on('contactsTagsChange', () => {
            this.load();
        });
        this.watcher1 = this.$rootScope.$on('contactsFilterChange', () => {
            this.load();
        });
        this.watcher2 = this.$rootScope.$on('appealsFlowsRefresh', (_event, status) => {
            if (status === this.config.status || (isUndefined(status) && this.config.status === FlowStatus.Asked)) {
                this.load();
            }
        });
        this.watcher3 = this.$rootScope.$on('appealsFlowsRemove', (_event, status, contact) => {
            if (status !== this.config.status) {
                this.data = reject({ id: contact.id }, this.data);
            }
        });
        this.load();
    }
    $onDestroy(): void {
        this.watcher0();
        this.watcher1();
        this.watcher2();
        this.watcher3();
    }
    load(): ng.IPromise<any> {
        this.loading = true;
        this.listLoadCount++;
        const currentCount = angular.copy(this.listLoadCount);
        return this.api
            .get({
                url: 'contacts',
                data: {
                    filter: this.filter(),
                    page: 1,
                    per_page: 1000,
                    include:
                        'pledges,pledges.appeal,donor_accounts,excluded_appeal_contacts,' +
                        'excluded_appeal_contacts.appeal,appeal_contacts,appeal_contacts.appeal',
                    fields: {
                        appeal_contacts: 'appeal',
                        appeals: '',
                        contacts:
                            'name,status,square_avatar,uncompleted_tasks_count,tag_list,pledges,pledge_currency,' +
                            'excluded_appeal_contacts,appeal_contacts,donor_accounts,starred_at',
                        donor_accounts: '',
                        excluded_appeal_contacts: 'reasons,appeal',
                        pledges: 'amount,amount_currency,expected_date,status,appeal',
                    },
                    sort: 'name',
                },
                overrideGetAsPost: true,
            })
            .then((data: any) => {
                if (currentCount !== this.listLoadCount) {
                    return;
                }
                this.loading = false;
                this.data = reduce(
                    (result, contact) => {
                        contact.appeal_contacts = filter(
                            ['appeal.id', this.$stateParams.appealId],
                            contact.appeal_contacts,
                        );
                        contact.excluded_appeal_contacts = filter(
                            ['appeal.id', this.$stateParams.appealId],
                            contact.excluded_appeal_contacts,
                        );
                        contact.pledges = filter(['appeal.id', this.$stateParams.appealId], contact.pledges);
                        result.push(contact);
                        return result;
                    },
                    [],
                    data,
                );
            });
    }
    filter(): any {
        return {
            ...this.contactFilter.buildFilterParams(),
            appeal: this.$stateParams.appealId,
            appeal_status: this.config.status,
        };
    }
    setContactStatus(): ng.IPromise<void> {
        if (!this.disabledDropzone()) {
            return this.appealsFlows.setContactStatus(
                this.$stateParams.appealId,
                this.appealsFlows.draggable,
                this.config.status,
            );
        } else {
            return this.$q.resolve();
        }
    }
    selectAll(): void {
        each((contact) => {
            if (!this.appealsFlows.contactSelected(contact.id)) {
                this.appealsFlows.toggleContact(contact.id);
            }
        }, this.data);
    }
    deselectAll(): void {
        each((contact) => {
            if (this.appealsFlows.contactSelected(contact.id)) {
                this.appealsFlows.toggleContact(contact.id);
            }
        }, this.data);
    }
    disabledDropzone(): boolean {
        return (
            !this.appealsFlows.draggable ||
            this.appealsFlows.draggableConfig === this.config ||
            (this.appealsFlows.draggableConfig.status === FlowStatus.Excluded &&
                this.config.status !== FlowStatus.Asked)
        );
    }
}

const Column = {
    controller: ColumnController,
    template: require('./column.html'),
    bindings: {
        config: '<',
    },
};

export default angular
    .module('mpdx.tools.appeals.flows.column.component', [uiRouter, api, appeals, appealsFlows, contactFilter])
    .component('appealsFlowsColumn', Column).name;
