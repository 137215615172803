import './coachingQuestions.scss';
import { IDeferred } from 'angular';
import coachingQuestions, { CoachingQuestionsService, IAnswerSet } from './coachingQuestions.service';

export class CoachingQuestionsController {
    answerSet: IAnswerSet;
    currentQuestion = 0;
    constructor(
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private deferred: IDeferred<any>,
        private coachingQuestions: CoachingQuestionsService,
    ) {
        this.activate();
    }
    activate() {
        this.coachingQuestions.getCurrentAnswerSet().then((answerSet) => {
            this.answerSet = answerSet;
        });
    }
    cancel(): ng.IPromise<void> {
        this.deferred.reject();
        this.$scope.$hide();
        return this.deferred.promise;
    }
    back(): void {
        if (this.currentQuestion > 0) {
            this.currentQuestion--;
        }
    }
    next(): ng.IPromise<void> {
        return this.coachingQuestions
            .saveAnswer(
                this.answerSet.id,
                this.answerSet.questions[this.currentQuestion].id,
                this.answerSet.questions[this.currentQuestion].answer,
            )
            .then(() => {
                if (this.currentQuestion < this.answerSet.questions.length - 1) {
                    this.currentQuestion++;
                } else {
                    this.deferred.resolve();
                    this.$scope.$hide();
                    return this.deferred.promise;
                }
            });
    }
    get firstQuestion() {
        return this.currentQuestion === 0;
    }
    get finalQuestion() {
        return this.currentQuestion >= this.answerSet?.questions.length - 1;
    }
}

export default angular
    .module('mpdx.coachingQuestions.service.modalController', [coachingQuestions])
    .controller('coachingQuestions', CoachingQuestionsController).name;
