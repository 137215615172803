import announcements, { AnnouncementsService, IAnnouncement, IAnnouncementAction } from './announcements.service';

class AnnouncementsController {
    announcement: IAnnouncement;
    constructor(private announcements: AnnouncementsService) {}
    $onInit(): void {
        this.load();
    }
    load(): ng.IPromise<void> {
        return this.announcements.load().then((data: IAnnouncement) => {
            this.announcement = data;
        });
    }
    perform(action: IAnnouncementAction): ng.IPromise<IAnnouncement> {
        let promise = this.announcements.perform(this.announcement, action);
        this.announcement = null;
        return promise;
    }
}

const Announcements = {
    controller: AnnouncementsController,
    template: require('./announcements.html'),
};

export default angular
    .module('mpdx.common.announcements.component', [announcements])
    .component('announcements', Announcements).name;
