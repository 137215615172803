import '@uirouter/angularjs';
import { get } from 'lodash/fp';
import accounts, { AccountsService } from '../../../../common/accounts/accounts.service';
import api, { ApiService } from '../../../../common/api/api.service';
import appeals, { AppealsService } from '../../../../tools/appeals/appeals.service';
import appealsShow, { AppealsShowService } from '../../../../tools/appeals/show/show.service';

class AppealsController {
    accountListId: string;
    appeal: any;
    watcher: () => void;
    constructor(
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private accounts: AccountsService,
        private api: ApiService,
        private appeals: AppealsService,
        private appealsShow: AppealsShowService,
    ) {
        this.watcher = $rootScope.$on('accountListUpdated', () => {
            this.$onInit();
        });
    }
    $onInit() {
        this.appeal = null;
        this.getPrimaryAppeal();
    }
    $onDestroy() {
        this.watcher();
    }
    getPrimaryAppeal() {
        return this.getPrimaryAppealIdOrCurrent().then((id) => {
            return id
                ? this.appealsShow.getAppeal(id).then((data) => {
                      this.appeal = data;
                  })
                : null;
        });
    }
    getPrimaryAppealIdOrCurrent() {
        return this.accountListId ? this.getPrimaryAppealId() : this.$q.resolve(this.getCurrentAppealId());
    }
    getPrimaryAppealId() {
        return this.api
            .get(`account_lists/${this.accountListId}`, {
                fields: {
                    account: 'primary_appeal',
                    appeal: '',
                },
                include: 'primary_appeal',
            })
            .then((data) => {
                return get('id', data.primary_appeal);
            });
    }
    getCurrentAppealId() {
        const currentAccount = get('current', this.accounts);
        const primaryAppeal = get('primary_appeal', currentAccount);
        return get('id', primaryAppeal);
    }
}

const progressAppeals = {
    template: require('./appeals.html'),
    controller: AppealsController,
    bindings: {
        accountListId: '<',
        inCoaching: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.progress.appeals', ['ui.router', accounts, api, appeals, appealsShow])
    .component('coachesShowProgressAppeals', progressAppeals).name;
