import 'angular-gettext';
import { StateService } from '@uirouter/core';
import designationAccounts, {
    DesignationAccountsService,
} from '../common/designationAccounts/designationAccounts.service';
import financialAccounts, { FinancialAccountsService } from '../common/financialAccounts/financialAccounts.service';
import help, { HelpService } from '../common/help/help.service';
import uiRouter from '@uirouter/angularjs';

export class ReportsController {
    watcher0: () => void;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $state: StateService, // used in view
        private gettextCatalog: ng.gettext.gettextCatalog,
        private financialAccounts: FinancialAccountsService,
        private designationAccounts: DesignationAccountsService,
        private help: HelpService,
    ) {}
    $onInit() {
        if (this.help.variables().HS_REPORTS_SUGGESTIONS?.length) {
            const suggestionsArray = [];
            this.help.variables().HS_REPORTS_SUGGESTIONS.forEach((i) => {
                suggestionsArray.push(this.gettextCatalog.getString(i));
            });
            this.help.suggest(suggestionsArray);
        }

        this.watcher0 = this.$rootScope.$on('accountListUpdated', () => {
            this.designationAccounts.load(true);
            this.financialAccounts.load(true);
        });
    }
    $onDestroy() {
        this.watcher0();
    }
}

const Reports = {
    controller: ReportsController,
    template: require('./reports.html'),
};

export default angular
    .module('mpdx.reports.component', ['gettext', uiRouter, designationAccounts, financialAccounts, help])
    .component('reports', Reports).name;
