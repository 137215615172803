import modal, { ModalService } from '../../../common/modal/modal.service';

class InvitePreferencesController {
    email: string;
    constructor(private modal: ModalService) {
        this.email = '';
    }
    confirmInvite() {
        return this.modal.open({
            controller: 'inviteConfirmController',
            template: require('./confirm/confirm.html'),
            locals: {
                email: this.email,
            },
        });
    }
}

const Invites = {
    controller: InvitePreferencesController,
    template: require('./invites.html'),
};

export default angular
    .module('mpdx.preferences.accounts.invites.component', [modal])
    .component('invitePreferences', Invites).name;
