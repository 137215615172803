import chart from './chart/chart.component';
import component from './financialAccounts.component';
import entries from './entries/entries.component';
import list from './list/list.component';
import report from './report/report.component';
import show from './show/show.component';
import summary from './summary/summary.component';

export default angular.module('mpdx.reports.financialAccounts', [
    component,
    entries,
    chart,
    list,
    report,
    show,
    summary,
]).name;
