export class SearchService {
    visible: boolean;
    constructor() {
        this.visible = false;
    }
    open(): void {
        this.visible = true;
    }
    close(): void {
        this.visible = false;
    }
}

export default angular.module('mpdx.common.search.service', []).service('search', SearchService).name;
