import 'angular-gettext';
import { calculateAverages, calculateTotals } from '../../../common/fp/objects';
import { sortBy } from 'lodash/fp';
import api, { ApiService } from '../../../common/api/api.service';
import help, { HelpService } from '../../../common/help/help.service';

export class SupportSummaryController {
    accountList: any;
    averages: any;
    data: any;
    loading: boolean;
    period: 'w' | 'm';
    showHelpButton = true;
    constructor(
        private $log: ng.ILogService,
        private api: ApiService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private help: HelpService,
    ) {
        if (!this.help.variables().HS_COACHING_ACTIVITY_SUMMARY?.length) {
            this.showHelpButton = false;
        }
    }
    $onChanges() {
        this.data = {};
        if (this.accountList) {
            this.load();
        }
    }
    load(): ng.IPromise<void> {
        this.loading = true;
        return this.api
            .get('reports/activity_results', {
                filter: {
                    account_list_id: this.accountList.id,
                    range: `4${this.period}`,
                },
            })
            .then((data) => {
                /* istanbul ignore next */
                this.$log.debug('reports/activity_results', data);
                this.data = sortBy('start_date', data);
                this.calculateAverages();
                this.loading = false;
            });
    }
    private calculateAverages() {
        const totals = this.calculateTotals();
        this.averages = calculateAverages(4, totals);
    }
    private calculateTotals() {
        let totals = {
            completed_call: 0,
            dials: 0,
            calls_with_appointment_next: 0,
            electronic_message_sent: 0,
            electronic_message_with_appointment_next: 0,
            completed_pre_call_letter: 0,
            completed_support_letter: 0,
            completed_thank: 0,
        };
        return calculateTotals(totals, 4, this.data);
    }
    showHelp(): void {
        this.help.article(this.gettextCatalog.getString(this.help.variables().HS_COACHING_ACTIVITY_SUMMARY));
    }
}

const SupportSummary: ng.IComponentOptions = {
    template: require('./supportSummary.html'),
    controller: SupportSummaryController,
    bindings: {
        accountList: '<',
        period: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.supportSummary.component', ['gettext', api, help])
    .component('supportSummary', SupportSummary).name;
