import 'angular-gettext';
import * as moment from 'moment';
import { find, get, map, reduce, reject, some, sumBy } from 'lodash/fp';
import { FlowStatus } from '../flows.service';
import accounts, { AccountsService } from '../../../../common/accounts/accounts.service';
import api, { ApiService } from '../../../../common/api/api.service';
import joinComma from '../../../../common/fp/joinComma';
import locale, { LocaleService } from '../../../../common/locale/locale.service';
import modal, { ModalService } from '../../../../common/modal/modal.service';
import mode from '../../../../common/fp/mode';

export class UpdateDonationsController {
    data: any[];
    loading: boolean;
    meta: {
        currency: string;
        saveEnabled: boolean;
        donationsOnLoad: boolean;
        total: number;
    };
    selectedDonations: any[];
    constructor(
        private $q: ng.IQService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService, // used in view
        private api: ApiService,
        private locale: LocaleService, // used in view
        private modal: ModalService,
        private appealId: string,
        private contact: any,
        private pledge: any,
        private deferred: ng.IDeferred<any>,
    ) {
        this.selectedDonations = [];
        this.meta = {
            saveEnabled: undefined,
            donationsOnLoad: false,
            total: 0,
            currency: undefined,
        };
        this.load();
    }
    load(): ng.IPromise<any> {
        if (this.contact.donor_accounts.length === 0) {
            this.data = [];
            return this.$q.resolve();
        }
        const donorAccountIds = joinComma(map('id', this.contact.donor_accounts));
        const params = {
            filter: { donor_account_id: donorAccountIds },
            include: 'appeal,designation_account',
            sort: '-donation_date',
            per_page: 25000,
        };
        this.loading = true;
        return this.api.get(`account_lists/${this.api.account_list_id}/donations`, params).then((data) => {
            this.loading = false;
            this.selectedDonations = reduce(
                (result, donation) => {
                    if (get('appeal.id', donation) === this.appealId) {
                        result.push(donation);
                    }
                    return result;
                },
                [],
                data,
            );
            this.data = data;
            this.updateMeta();
        });
    }
    donationSelected(donation: { id: string }): boolean {
        return some(['id', donation.id], this.selectedDonations);
    }
    toggleDonation(donation: { id: string }): void {
        if (this.donationSelected(donation)) {
            this.selectedDonations = reject(['id', donation.id], this.selectedDonations);
        } else {
            this.selectedDonations.push(donation);
        }
        this.updateMeta();
    }
    save(): ng.IPromise<any> {
        if (this.meta.total === 0) {
            return this.totalZero();
        } else if (this.pledge && this.meta.total < this.pledge.amount) {
            return this.totalLessThanPledge();
        } else {
            return this.commit({
                ...this.pledge,
                amount: this.meta.total,
                amount_currency: this.meta.currency,
                status: FlowStatus.Processed,
            });
        }
    }
    commit(pledge: any): ng.IPromise<any> {
        const donations = [...this.disconnectDonations(), ...this.connectDonations()];
        let promise;
        if (donations.length === 0) {
            promise = this.$q.resolve();
        } else {
            promise = this.api.put({
                url: `account_lists/${this.api.account_list_id}/donations/bulk`,
                data: donations,
                type: 'donations',
                fields: {
                    donations: '',
                },
            });
        }
        return promise.then(() => {
            if (pledge.id) {
                return this.api
                    .put({
                        url: `account_lists/${this.api.account_list_id}/pledges/${pledge.id}`,
                        data: pledge,
                    })
                    .then(() => {
                        this.deferred.resolve(pledge);
                        this.$scope.$hide();
                    });
            } else if (donations.length === 0) {
                pledge.appeal = { id: this.appealId };
                pledge.contact = { id: this.contact.id };
                pledge.expected_date = moment().format('YYYY-MM-DD');
                return this.api
                    .post({
                        url: `account_lists/${this.api.account_list_id}/pledges`,
                        data: pledge,
                    })
                    .then(() => {
                        this.deferred.resolve(pledge);
                        this.$scope.$hide();
                    });
            } else {
                this.deferred.resolve(pledge);
                this.$scope.$hide();
            }
        });
    }
    private totalZero(): ng.IPromise<any> {
        let status: FlowStatus, statusText: string;
        if (this.pledge) {
            status = FlowStatus.ReceivedNotProcessed;
            statusText = this.gettextCatalog.getString('Received');
        } else {
            status = FlowStatus.Asked;
            statusText = this.gettextCatalog.getString('Asked');
        }
        const message = this.gettextCatalog.getString(
            'The total amount is zero. As a result, the contact will be moved to the {{statusText}} column. ' +
                'Are you sure?',
            { statusText },
        );
        return this.modal.confirm(message).then(() => this.commit({ ...this.pledge, status }));
    }
    private totalLessThanPledge(): ng.IPromise<any> {
        const message = this.gettextCatalog.getString(
            'The total amount is less than the commitment amount. ' +
                'Would you like to update the commitment amount to match the total? ' +
                'If not, the contact will be moved to the Received column.',
        );
        return this.modal
            .confirm(message)
            .then(() => {
                const pledge = {
                    ...this.pledge,
                    amount: this.meta.total,
                    amount_currency: this.meta.currency,
                    status: FlowStatus.Processed,
                };
                return this.commit(pledge);
            })
            .catch(() => {
                const pledge = {
                    ...this.pledge,
                    status: FlowStatus.ReceivedNotProcessed,
                };
                return this.commit(pledge);
            });
    }
    private connectDonations(): { id: string; appeal: { id: string } }[] {
        return reduce(
            (result, donation) => {
                const initialDonation = find(['id', donation.id], this.data);
                if (get('appeal.id', initialDonation) !== this.appealId) {
                    result.push({
                        id: donation.id,
                        appeal: { id: this.appealId },
                    });
                }
                return result;
            },
            [],
            this.selectedDonations,
        );
    }
    private disconnectDonations(): { id: string; appeal: { id: string } }[] {
        return reduce(
            (result, donation) => {
                const appealMatch = get('appeal.id', donation) === this.appealId;
                const donationNotSelected = !some(['id', donation.id], this.selectedDonations);
                if (appealMatch && donationNotSelected) {
                    result.push({
                        id: donation.id,
                        appeal: { id: 'none' },
                    });
                }
                return result;
            },
            [],
            this.data,
        );
    }
    private updateMeta(): void {
        this.meta.donationsOnLoad = this.selectedDonations.length > 0;
        if (this.data.length > 0) {
            if (this.selectedDonations.length > 0 || this.meta.donationsOnLoad) {
                this.meta.saveEnabled = true;
            } else {
                this.meta.saveEnabled = undefined;
            }
        } else {
            this.meta.saveEnabled = undefined;
        }
        this.meta.currency = mode(map('currency', this.selectedDonations));
        this.meta.total = sumBy('converted_amount', this.selectedDonations);
    }
}

export default angular
    .module('mpdx.tools.appeals.flows.updateDonation.controller', ['gettext', accounts, api, locale, modal])
    .controller('appealsFlowsUpdateDonationsController', UpdateDonationsController).name;
