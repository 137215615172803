import { assign, concat, filter, find, flatten, get, isNil, map, reduce } from 'lodash/fp';
import { FiltersService } from '../../../common/filters/filters.service';
import { StateParams } from '@uirouter/core';
import { stringToNameObjectArray } from '../../../common/fp/tags';
import analysisFilter, { AnalysisFilterService } from '../filter/filter.service';
import contactsTags, { ContactsTagsService } from '../../../contacts/sidebar/filter/tags/tags.service';
import modal, { ModalService } from '../../../common/modal/modal.service';
import session, { SessionService } from '../../../common/session/session.service';
import users, { UsersService } from '../../../common/users/users.service';

export class SidebarController {
    activeFilters: any[];
    reportFiltersAreCollapsed: boolean;
    dateRangeLocale: any;
    donationPeriodSliderOnEnd: (id: string) => void;
    isCollapsed: boolean;
    reportFilters: any[];
    allFilters: any[];
    watcher0: () => void;
    watcher1: () => void;
    watcher2: () => void;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $scope: ng.IScope,
        private $stateParams: StateParams,
        private analysisFilter: AnalysisFilterService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private contactsTags: ContactsTagsService,
        private filters: FiltersService,
        private modal: ModalService,
        private session: SessionService, // used in view
        private users: UsersService,
    ) {
        this.activeFilters = [];
        this.donationPeriodSliderOnEnd = (id) => {
            const matchedFilter: any = find({ name: id } as any, this.allFilters);
            if (matchedFilter) {
                this.analysisFilter.params[matchedFilter.name] = angular.copy(
                    this.analysisFilter.params[matchedFilter.name],
                );
                this.analysisFilter.change(matchedFilter);
            }
        };
    }
    $onInit(): void {
        this.dateRangeLocale = {
            applyLabel: this.gettextCatalog.getString('Filter'),
            cancelLabel: this.gettextCatalog.getString('Clear'),
        };
        if (angular.isObject(this.$stateParams.filters)) {
            this.analysisFilter.params = (assign as any)(this.analysisFilter.params, this.$stateParams.filters, {});
        }
        this.isCollapsed = this.users.getCurrentOptionValue('contact_filters_collapse');
        this.watcher0 = this.$scope.$watch('$ctrl.isCollapsed', (newVal) => {
            if (!isNil(newVal)) {
                this.users.saveOption('contact_filters_collapse', this.isCollapsed);
            }
        });
        this.reportFiltersAreCollapsed = this.users.getCurrentOptionValue('report_filters_collapse');
        this.watcher1 = this.$scope.$watch('$ctrl.reportFiltersAreCollapsed', (newVal) => {
            if (!isNil(newVal)) {
                this.users.saveOption('report_filters_collapse', this.reportFiltersAreCollapsed);
                this.$rootScope.$emit('reportFiltersAreCollapsed', newVal);
            }
        });
        this.watcher2 = this.$rootScope.$on('analysisFilterLoad', () => this.onAnalysisFilterLoad());
        this.onAnalysisFilterLoad();
    }
    $onDestroy(): void {
        this.watcher0();
        this.watcher1();
        this.watcher2();
    }
    reset(): void {
        if (this.analysisFilter.isResettable()) {
            this.analysisFilter.reset();
        }
    }
    useSavedFilter(name): void {
        const option = this.users.getCurrentOptionValue(`saved_analysis_filter_${name}`);
        const value = JSON.parse(option);
        const params = this.filters.fromStrings(value.params, this.analysisFilter.data);
        this.analysisFilter.params = assign(this.analysisFilter.defaultParams, params);
        this.analysisFilter.wildcardSearch = value.wildcard_search;
        this.contactsTags.anyTags = value.any_tags;
        this.contactsTags.rejectedTags = stringToNameObjectArray(value.exclude_tags);
        this.contactsTags.selectedTags = stringToNameObjectArray(value.tags);
        this.analysisFilter.change();
        this.analysisFilter.selectedSave = name;
    }
    openSaveModal(): ng.IPromise<any> {
        return this.modal.open({
            controller: 'saveFilterModal',
            template: require('../../../common/filters/save/save.html'),
            locals: {
                anyTags: this.contactsTags.anyTags,
                filterType: 'analysis',
                params: this.filters.findChangedFilters(this.analysisFilter.defaultParams, this.analysisFilter.params),
                rejectedTags: this.contactsTags.rejectedTags,
                selectedTags: this.contactsTags.selectedTags,
                wildcardSearch: this.analysisFilter.wildcardSearch,
            },
        });
    }
    includeFilter(filter: any): boolean {
        const reportFilterIdsToExclude = [
            'donation_period_sum',
            'donation_period_count',
            'donation_period_average',
            'donation_period_percent_rank',
            'donation_amount_range',
            'donation_date',
            'donation',
            'donation_currency',
            'designation_account_id',
        ];
        return reportFilterIdsToExclude.indexOf(filter.name) === -1;
    }
    includeReportFilter(filter: any): boolean {
        const reportFilterIdsToInclude = [
            'donation_date',
            'donation_period_sum',
            'donation_period_count',
            'donation_period_average',
            'donation_period_percent_rank',
            'donation_currency',
        ];
        return reportFilterIdsToInclude.indexOf(filter.name) !== -1;
    }
    onAnalysisFilterLoad(): void {
        this.allFilters = flatten(map((f) => get('children', f) || f, this.analysisFilter.data));
        this.reportFilters = reduce(
            (result, analysisFilter) => {
                if (this.includeReportFilter(analysisFilter)) {
                    result.push(analysisFilter);
                }
                if (get('children', analysisFilter)) {
                    result = concat(
                        result,
                        filter((analysisFilter) => {
                            return this.includeReportFilter(analysisFilter);
                        }, analysisFilter.children),
                    );
                }
                return result;
            },
            [],
            this.analysisFilter.data,
        );
    }
}

const Sidebar = {
    controller: SidebarController,
    template: require('./sidebar.html'),
};

export default angular
    .module('mpdx.reports.analysis.sidebar.component', [analysisFilter, contactsTags, modal, session, users])
    .component('analysisSidebar', Sidebar).name;
