import 'angular-gettext';
import { reject } from 'lodash/fp';
import accounts, { AccountsService } from '../../../common/accounts/accounts.service';
import users, { UsersService } from '../../../common/users/users.service';

class SharePreferencesController {
    inviteEmail: string;
    saving: boolean;
    setup: boolean;
    private appName: string = process.env.APP_NAME;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService,
        private users: UsersService,
    ) {
        this.saving = false;
        this.inviteEmail = '';
    }
    $onInit() {
        if (this.setup) {
            return;
        }

        this.accounts.listCoaches();
        this.accounts.listCoachesInvites();

        this.$rootScope.$on('accountListUpdated', () => {
            this.accounts.listCoaches();
            this.accounts.listCoachesInvites();
        });
    }
    removeCoachInvite(id) {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString('{{app_name}} removed the coaching invite successfully', {
            app_name: process.env.APP_NAME,
        });
        const errorMessage = this.gettextCatalog.getString("{{app_name}} couldn't remove the coaching invite", {
            app_name: process.env.APP_NAME,
        });
        return this.accounts
            .destroyInvite(id, successMessage, errorMessage)
            .then(() => {
                this.saving = false;
                this.accounts.inviteCoachList = reject({ id: id }, this.accounts.inviteCoachList);
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
    removeCoach(id) {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString('{{app_name}} removed the coach successfully', {
            app_name: process.env.APP_NAME,
        });
        const errorMessage = this.gettextCatalog.getString("{{app_name}} couldn't remove the coach", {
            app_name: process.env.APP_NAME,
        });
        return this.accounts
            .destroyCoach(id, successMessage, errorMessage)
            .then(() => {
                this.saving = false;
                this.accounts.coachList = reject({ id: id }, this.accounts.coachList);
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
}

const Share = {
    controller: SharePreferencesController,
    template: require('./share.html'),
    bindings: {
        setup: '<',
    },
};

export default angular
    .module('mpdx.preferences.coaches.share', ['gettext', accounts, users])
    .component('coachesSharePreferences', Share).name;
