const progressPhoneDials = {
    template: require('./phone.html'),
    bindings: {
        analytics: '<',
        endDate: '<',
        inCoaching: '<',
        startDate: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.progress.phone.component', [])
    .component('coachesShowProgressPhoneDials', progressPhoneDials).name;
