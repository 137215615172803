import coachingQuestions, {
    CoachingQuestionsService,
    IAnswerSet,
} from '../../../coachingQuestions/coachingQuestions.service';

export class WeeklyReportController {
    accountList?: { id: string };
    answerSets: IAnswerSet[];
    currentAnswerSet = 0;
    loading: boolean;
    constructor(private coachingQuestions: CoachingQuestionsService) {}
    $onChanges() {
        this.answerSets = [];
        this.currentAnswerSet = 0;
        this.load();
    }
    load(): ng.IPromise<void> {
        this.loading = true;
        if (!this.accountList) {
            return; // This is dependent on an API request, so avoid loading until it becomes defined
        }
        return this.coachingQuestions.getCompletedAnswerSets(this.accountList.id).then((answerSets) => {
            this.answerSets = answerSets;
            this.loading = false;
        });
    }
    hasNext() {
        return this.currentAnswerSet > 0;
    }
    next() {
        if (this.hasNext()) {
            this.currentAnswerSet--;
        }
    }
    hasPrevious() {
        return this.currentAnswerSet < this.answerSets.length - 1;
    }
    previous() {
        if (this.hasPrevious()) {
            this.currentAnswerSet++;
        }
    }
}

const WeeklyReport: ng.IComponentOptions = {
    template: require('./weeklyReport.html'),
    controller: WeeklyReportController,
    bindings: {
        accountList: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.weeklyReport.component', [coachingQuestions])
    .component('weeklyReport', WeeklyReport).name;
