import edit from './edit/edit.controller';
import exportCustom from './export/custom/custom.controller';
import exportMailchimp from './export/mailchimp/mailchimp.controller';
import exportMailingPdf from './export/mailingPdf/mailingPdf.controller';
import map from './map/map.controller';
import merge from './merge/merge.controller';

export default angular.module('mpdx.contacts.bulkActions.contact', [
    edit,
    exportCustom,
    exportMailchimp,
    exportMailingPdf,
    map,
    merge,
]).name;
