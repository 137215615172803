import { isNil } from 'lodash/fp';
import designationAccounts, {
    DesignationAccountsService,
} from '../../common/designationAccounts/designationAccounts.service';
import financialAccounts, { FinancialAccountsService } from '../../common/financialAccounts/financialAccounts.service';
import session, { SessionService } from '../../common/session/session.service';
import users, { UsersService } from '../../common/users/users.service';

export class SidebarController {
    watcher0: () => void;
    watcher1: () => void;
    reportFiltersAreCollapsed: boolean;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $scope: ng.IScope,
        private designationAccounts: DesignationAccountsService, // used in view
        private financialAccounts: FinancialAccountsService, // used in view
        private session: SessionService, // used in view
        private users: UsersService,
    ) {}
    $onInit(): void {
        this.reportFiltersAreCollapsed = this.users.getCurrentOptionValue('report_filters_collapse');
        this.watcher0 = this.$scope.$watch('$ctrl.reportFiltersAreCollapsed', (newVal) => {
            if (!isNil(newVal) && this.users.getCurrentOptionValue('report_filters_collapse') !== newVal) {
                this.users.saveOption('report_filters_collapse', this.reportFiltersAreCollapsed);
            }
        });
        this.watcher1 = this.$rootScope.$on('reportFiltersAreCollapsed', (_event, newVal) => {
            this.reportFiltersAreCollapsed = newVal;
        });
    }
    $onDestroy(): void {
        this.watcher0();
        this.watcher1();
    }
}

const Sidebar = {
    controller: SidebarController,
    template: require('./sidebar.html'),
};

export default angular
    .module('mpdx.reports.sidebar.component', [designationAccounts, financialAccounts, session, users])
    .component('reportsSidebar', Sidebar).name;
