import locale, { LocaleService } from '../locale/locale.service';

class DisplayController {
    dayFirst: boolean;
    yearFirst: boolean;
    constructor(private locale: LocaleService) {
        this.dayFirst = false;
        this.yearFirst = false;
    }
    $onChanges() {
        const format = this.locale.dateTimeFormat;
        const yearIndex = format.indexOf('y');
        const monthIndex = format.indexOf('M');
        const dayIndex = format.indexOf('d');
        this.yearFirst = yearIndex < monthIndex;
        this.dayFirst = dayIndex < monthIndex;
    }
}

const Display: ng.IComponentOptions = {
    template: require('./display.html'),
    controller: DisplayController,
    bindings: {
        year: '=',
        month: '=',
        day: '=',
    },
};

export default angular
    .module('mpdx.common.threePartDateDisplay.component', [locale])
    .component('threePartDateDisplay', Display).name;
