import * as Bowser from 'bowser';
import * as moment from 'moment';
import { find, get } from 'lodash/fp';
import { flattenCompactAndJoin } from '../../../../common/fp/flattenCompactAndJoin';
import contacts, { ContactsService } from '../../../../contacts/contacts.service';
import people, { PeopleService } from '../../../../contacts/show/people/people.service';
import serverConstants, { ServerConstantsService } from '../../../../common/serverConstants/serverConstants.service';

export class ItemController {
    contact: any;
    currency: any;
    isSafari: boolean;
    constructor(
        private $window: ng.IWindowService,
        private contacts: ContactsService,
        private people: PeopleService, // used in view
        private serverConstants: ServerConstantsService,
    ) {}
    $onInit() {
        const browser = Bowser.getParser(this.$window.navigator.userAgent);
        this.isSafari = browser.getBrowserName() === 'Safari';
    }
    $onChanges() {
        const pledgeCurrency = get('pledge_currency', this.contact);
        this.currency = find({ code: pledgeCurrency }, this.serverConstants.data.pledge_currencies);
    }
    daysLate() {
        return moment().diff(moment(this.contact.late_at), 'days') || 0;
    }
    emailAll() {
        const emails = flattenCompactAndJoin((email) => email, this.contacts.getEmailsFromPeople(this.contact.people));
        if (this.isSafari) {
            this.$window.href = `mailto:${emails}`;
        } else {
            this.$window.open(`mailto:${emails}`);
        }
    }
}

const Item = {
    controller: ItemController,
    template: require('./item.html'),
    bindings: {
        contact: '=',
        loaded: '<', // triggers onchange once nested data is loaded
    },
};

export default angular
    .module('mpdx.tasks.list.drawer.contact.component', [contacts, people, serverConstants])
    .component('taskListContact', Item).name;
