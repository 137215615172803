import { daysLate } from '../../../common/fp/dates';
import { flatten, includes, map, pull, union } from 'lodash/fp';
import contacts, { ContactsService } from '../../../contacts/contacts.service';
import people, { PeopleService } from '../../../contacts/show/people/people.service';
import tasks, { TasksService } from '../../../tasks/tasks.service';

export class ItemController {
    contact: any;
    tagsExpanded: any;
    watcher: any;
    watcher2: any;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private contacts: ContactsService,
        private people: PeopleService, // used in view
        private tasks: TasksService,
    ) {
        this.tagsExpanded = false;
    }
    $onInit(): void {
        this.watcher = this.$rootScope.$on('contactTagDeleted', (e, val) => {
            if (!val.contactIds || includes(this.contact.id, val.contactIds)) {
                this.contact.tag_list = pull(val.tag, this.contact.tag_list);
            }
        });
        this.watcher2 = this.$rootScope.$on('contactTagsAdded', (e, val) => {
            if (!val.contactIds || includes(this.contact.id, val.contactIds)) {
                this.contact.tag_list = union(this.contact.tag_list, val.tags);
            }
        });
    }
    $onDestroy(): void {
        this.watcher();
        this.watcher2();
    }
    hasSendNewsletterError(): boolean {
        if (!this.contact.addresses || !this.contact.people) {
            return false;
        }
        const missingAddress = this.contact.addresses.length === 0;
        const missingEmailAddress = flatten(map('email_addresses', this.contact.people)).length === 0;
        switch (this.contact.send_newsletter) {
            case 'Both':
                return missingAddress || missingEmailAddress;
            case 'Physical':
                return missingAddress;
            case 'Email':
                return missingEmailAddress;
        }
        return false;
    }
    daysLate(): number {
        return daysLate(this.contact.late_at);
    }
    star(): ng.IPromise<any> {
        return this.contacts.star(this.contact).then((data) => {
            this.contact.starred_at = data.starred_at;
        });
    }
    logTask(): ng.IPromise<void> {
        return this.tasks.logModal([this.contact.id]);
    }
}

const Item = {
    controller: ItemController,
    template: require('./item.html'),
    bindings: {
        contact: '=',
    },
};

export default angular
    .module('mpdx.contacts.list.item.component', [contacts, people, tasks])
    .component('contactsListItem', Item).name;
