import * as moment from 'moment';
import accounts, { AccountsService } from '../common/accounts/accounts.service';
import api, { ApiService } from '../common/api/api.service';
import help, { HelpService } from '../common/help/help.service';
import locale, { LocaleService } from '../common/locale/locale.service';
import modal, { ModalService } from '../common/modal/modal.service';
import tasks, { TasksService } from '../tasks/tasks.service';

export class HomeController {
    watcher0: () => void;
    watcher1: () => void;
    latestNewsletterCompletedAt: string;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService, // used in view
        private api: ApiService,
        private help: HelpService,
        private locale: LocaleService, // used in view
        private modal: ModalService,
        private tasks: TasksService,
    ) {}
    $onInit(): void {
        if (this.help.variables().HS_HOME_SUGGESTIONS?.length) {
            const suggestionsArray = [];
            this.help.variables().HS_HOME_SUGGESTIONS.forEach((i) => {
                suggestionsArray.push(this.gettextCatalog.getString(i));
            });
            this.help.suggest(suggestionsArray);
        }
        this.load();

        this.watcher0 = this.$rootScope.$on('taskChange', () => {
            this.load();
        });

        this.watcher1 = this.$rootScope.$on('accountListUpdated', () => {
            this.load();
        });
    }
    $onDestroy(): void {
        this.watcher0();
        this.watcher1();
    }
    load(): ng.IPromise<void> {
        return this.tasks.getAnalytics().then(() => {
            if (
                this.tasks.analytics.last_electronic_newsletter_completed_at &&
                this.tasks.analytics.last_physical_newsletter_completed_at
            ) {
                this.latestNewsletterCompletedAt = moment
                    .max([
                        moment(this.tasks.analytics.last_electronic_newsletter_completed_at),
                        moment(this.tasks.analytics.last_physical_newsletter_completed_at),
                    ])
                    .toISOString();
            } else {
                this.latestNewsletterCompletedAt =
                    this.tasks.analytics.last_electronic_newsletter_completed_at ||
                    this.tasks.analytics.last_physical_newsletter_completed_at;
            }
        });
    }
    addNewsletter(): ng.IPromise<void> {
        return this.modal
            .open({
                template: require('../tasks/modals/newsletter/newsletter.html'),
                controller: 'newsletterTaskController',
            })
            .then(() => {
                this.$rootScope.$emit('taskChange');
            });
    }
    exportPhysical(): ng.IPromise<void> {
        return this.modal.open({
            template: require('../contacts/bulkActions/contact/export/custom/custom.html'),
            controller: 'contactsBulkActionsContactExportCustomController',
            locals: {
                selectedContactIds: [],
                filters: {
                    account_list_id: this.api.account_list_id,
                    newsletter: 'address',
                    status: 'active',
                },
            },
        });
    }
    exportEmail(): ng.IPromise<void> {
        return this.modal.open({
            template: require('./export/export.html'),
            controller: 'exportContactEmailsController',
        });
    }
}

const Home = {
    template: require('./home.html'),
    controller: HomeController,
};

export default angular
    .module('mpdx.home.component', [accounts, api, help, locale, modal, tasks])
    .component('home', Home).name;
