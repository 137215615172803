export class CareController {
    tab: 'prayers' | 'celebrations' = 'prayers';
}

const Care = {
    template: require('./care.html'),
    controller: CareController,
};

export default angular.module('mpdx.home.care.component', []).component('homeCare', Care).name;
