import api, { ApiService } from '../../../common/api/api.service';

export class TagsController {
    accountList: any;
    data: any;
    loading: boolean;
    association: string;
    period: 'w' | 'm';
    constructor(private api: ApiService) {}
    $onChanges() {
        this.data = [];
        this.load();
    }
    load(): ng.IPromise<void> {
        if (!this.accountList) {
            return; // This is dependent on an API request, so avoid loading until it becomes defined
        }
        this.loading = true;
        return this.api
            .get('reports/tag_histories', {
                filter: {
                    account_list_id: this.accountList.id,
                    association: this.association,
                    range: `6${this.period}`,
                },
            })
            .then((data) => {
                this.data = data;
                this.loading = false;
            });
    }
}

const Tags: ng.IComponentOptions = {
    template: require('./tags.html'),
    controller: TagsController,
    bindings: {
        accountList: '<',
        period: '<',
        association: '@',
    },
};

export default angular.module('mpdx.coaches.show.tags.component', [api]).component('tags', Tags).name;
