export class AddressFinderService {
    cache: {
        [key: string]: google.maps.places.AutocompletePrediction[];
    };
    constructor() {
        this.cache = {};
    }
}

export default angular.module('mpdx.common.addressFinder.service', []).service('addressFinder', AddressFinderService)
    .name;
