const progressContacts = {
    template: require('./contacts.html'),
    bindings: {
        analytics: '<',
        inCoaching: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.progress.contacts', [])
    .component('coachesShowProgressContacts', progressContacts).name;
