import { concat, defaultTo, find, map, reduce } from 'lodash/fp';
import accounts, { AccountsService } from '../../../common/accounts/accounts.service';
import createPatch from '../../../common/fp/createPatch';
import isNilOrEmpty from '../../../common/fp/isNilOrEmpty';
import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';
import tasks, { TasksService } from '../../tasks.service';
import tasksTags, { TasksTagsService } from '../../filter/tags/tags.service';

class EditTaskController {
    task: any;
    taskInitialState: any;
    appName: string = process.env.APP_NAME;
    watcher: () => void;
    watcher2: () => void;
    constructor(
        private $log: ng.ILogService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private accounts: AccountsService, // used in view
        private serverConstants: ServerConstantsService, // used in view
        private tasks: TasksService,
        private tasksTags: TasksTagsService, // used in view
        task: any,
    ) {
        this.$log = $log;
        this.$scope = $scope;
        this.serverConstants = serverConstants;
        this.tasksTags = tasksTags;
        this.tasks = tasks;

        this.task = angular.copy(task);
        this.taskInitialState = angular.copy(task);

        this.watcher = $scope.$watch('$ctrl.task.start_at', (newVal, oldVal) => {
            const isOld = isNilOrEmpty(newVal) && !isNilOrEmpty(oldVal);
            this.task.notification_time_before = isOld ? null : this.task.notification_time_before;
            this.task.notification_type = isOld ? null : this.task.notification_type;
        });

        this.watcher2 = $scope.$watch('$ctrl.task.notification_time_before', (newVal, oldVal) => {
            const isNew = !isNilOrEmpty(newVal) && isNilOrEmpty(oldVal);
            const isOld = isNilOrEmpty(newVal) && !isNilOrEmpty(oldVal);
            this.task.notification_type = isNew ? 'both' : isOld ? null : this.task.notification_type;
        });

        $scope.$on('$destroy', () => {
            this.watcher();
            this.watcher2();
        });

        this.task.subject = this.task.subject_hidden ? null : this.task.subject;
    }
    save() {
        this.handleActivityContacts();
        this.handleDates();
        this.handleHiddenSubjects();

        let patch = createPatch(this.taskInitialState, this.task);
        /* istanbul ignore next */
        this.$log.debug('task patch', patch);

        return this.tasks.save(patch).then(() => {
            this.$scope.$hide();
            this.handleFollowUp(patch);
        });
    }
    private handleActivityContacts(): void {
        this.task.activity_contacts = map((activity) => {
            if (!find({ id: activity.contact.id }, this.task.contacts)) {
                activity._destroy = 1;
            }
            return activity;
        }, this.task.activity_contacts);
        this.task.contacts = reduce(
            (result, value) => {
                return find((a) => a.contact.id === value.id, this.task.activity_contacts)
                    ? result
                    : concat(result, value);
            },
            [],
            this.task.contacts,
        );
    }
    private handleDates(): void {
        this.task.start_at = this.isoDateOrNull(this.task.start_at);
        this.task.completed_at = this.isoDateOrNull(this.task.completed_at);
    }
    private isoDateOrNull(val): boolean {
        return this.isIsoDate(val) ? val : null;
    }
    private isIsoDate(s): boolean {
        const isoDateRegExp = new RegExp(
            /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/,
        );
        return isoDateRegExp.test(s);
    }
    private handleHiddenSubjects(): void {
        this.task.subject_hidden = isNilOrEmpty(this.task.subject);
        this.task.subject = this.task.subject_hidden ? this.taskInitialState.subject : this.task.subject;
    }
    delete(): ng.IPromise<void> {
        return this.tasks.delete(this.task).then(() => {
            this.$scope.$hide();
        });
    }
    private handleFollowUp(patch): boolean | ng.IPromise<void> {
        return patch.next_action && patch.next_action !== 'None'
            ? this.tasks.addModal({
                  activityType: patch.next_action,
                  comments: defaultTo([], this.task.comments),
                  contactsList: map('id', this.task.contacts),
                  task: this.task,
              })
            : true;
    }
}

export default angular
    .module('mpdx.tasks.modals.edit.controller', [accounts, serverConstants, tasksTags, tasks])
    .controller('editTaskController', EditTaskController).name;
