import 'angular-gettext';
import api, { ApiService } from '../api/api.service';
import serverConstants, { ServerConstantsService } from '../serverConstants/serverConstants.service';

export class LanguageService {
    dateTimeFormat: string;
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private serverConstants: ServerConstantsService,
    ) {
        this.dateTimeFormat = null;
    }
    change(language: string): void {
        let temp = angular.copy(language);
        // hardcoded until the data is fixed
        switch (language) {
            case 'zh-hans-cn':
                language = 'zh_Hans_CN';
                temp = 'zh-Hans-CN';
                break;
            case 'nl-nl':
                language = 'nl_nl';
                temp = 'nl-NL';
                break;
            case 'en-us':
                language = 'en_us';
                temp = 'en-US';
                break;
            case 'fr-ca':
                language = 'fr_ca';
                temp = 'fr-CA';
                break;
            case 'de-ch':
                language = 'de_ch';
                temp = 'de-CH';
                break;
            case 'fr-fr':
                language = 'fr_FR';
                temp = 'fr-FR';
                break;
            case 'es-419':
                language = 'es_419';
                break;
            case 'pt-br':
                temp = 'pt-BR';
                language = 'pt_br';
                break;
        }
        this.api.language = language;
        this.gettextCatalog.setCurrentLanguage(language);
        this.serverConstants.refresh();

        if (process.env.NODE_ENV !== 'development') {
            this.gettextCatalog.loadRemote(`locale/${temp}-${process.env.AWS_COMMIT_ID}.json`);
        }
    }
}

export default angular
    .module('mpdx.common.language.service', ['gettext', api, serverConstants])
    .service('language', LanguageService).name;
