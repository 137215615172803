import contacts, { ContactsService } from '../../../../contacts.service';
import mailchimp, { MailchimpService } from '../../../../../preferences/integrations/mailchimp/mailchimp.service';

export class MailchimpController {
    mailchimpListId: string;
    constructor(
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private contacts: ContactsService,
        private mailchimp: MailchimpService, // used in view
        private selectedContactIds: string[],
    ) {}
    export(): ng.IPromise<void> {
        return this.contacts.exportMailchimp(this.mailchimpListId, this.selectedContactIds).then(() => {
            this.$scope.$hide();
        });
    }
}

export default angular
    .module('mpdx.contacts.bulkActions.export.mailchimp.controller', [contacts, mailchimp])
    .controller('contactsBulkActionsContactExportMailchimpController', MailchimpController).name;
