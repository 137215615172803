import accountLists from './accountLists/accountLists.component';
import component from './organizations.component';
import contacts from './contacts/contacts.component';
import impersonateUser from './impersonateUser/impersonateUser.component';
import invite from './invite/invite.component';
import service from './organizations.service';
import share from './share/share.component';

export default angular.module('mpdx.preferences.organizations', [
    accountLists,
    component,
    contacts,
    impersonateUser,
    invite,
    service,
    share,
]).name;
