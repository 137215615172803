import 'angular-gettext';
import { StateParams, StateService } from '@uirouter/core';
import help, { HelpService } from '../common/help/help.service';
import session, { SessionService } from '../common/session/session.service';
import tools, { ToolsService } from './tools.service';
import uiRouter from '@uirouter/angularjs';
import users, { UsersService } from '../common/users/users.service';

class ToolsController {
    dropdown: boolean;
    setup: boolean;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private $stateParams: StateParams,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private help: HelpService,
        private session: SessionService,
        private tools: ToolsService,
        private users: UsersService,
    ) {
        this.setup = $stateParams.setup;
        this.dropdown = false;
    }
    $onInit() {
        if (this.help.variables().HS_TOOLS_SUGGESTIONS?.length) {
            const suggestionsArray = [];
            this.help.variables().HS_TOOLS_SUGGESTIONS.forEach((i) => {
                suggestionsArray.push(this.gettextCatalog.getString(i));
            });
            this.help.suggest(suggestionsArray);
        }

        this.$rootScope.$on('accountListUpdated', () => {
            this.tools.getAnalytics(true);
        });

        this.tools.getAnalytics();
    }
}

const Tools: ng.IComponentOptions = {
    controller: ToolsController,
    template: require('./tools.html'),
};

export default angular
    .module('mpdx.tools.component', ['gettext', uiRouter, help, session, tools, users])
    .component('tools', Tools).name;
