import 'angular-gettext';
import { StateParams } from '@uirouter/core';
import help, { HelpService } from '../../common/help/help.service';
import uiRouter from '@uirouter/angularjs';

class CoachesController {
    tabId: string;
    constructor(
        private $stateParams: StateParams,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private help: HelpService,
    ) {
        this.tabId = 'share_coaching_account';
    }
    $onInit() {
        if (this.help.variables().HS_SETTINGS_COACHES_SUGGESTIONS?.length) {
            const suggestionsArray = [];
            this.help.variables().HS_SETTINGS_COACHES_SUGGESTIONS.forEach((i) => {
                suggestionsArray.push(this.gettextCatalog.getString(i));
            });
            this.help.suggest(suggestionsArray);
        }

        if (this.$stateParams.id) {
            this.setTab(this.$stateParams.id);
        }
    }
    setTab(service) {
        if (this.tabId === service) {
            this.tabId = '';
        } else {
            this.tabId = service;
        }
    }
}

const Coaches = {
    controller: CoachesController,
    template: require('./coaches.html'),
};

export default angular
    .module('mpdx.preferences.coaches.component', [uiRouter, 'gettext', help])
    .component('preferencesCoaches', Coaches).name;
