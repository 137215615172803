import analysisFilter, { AnalysisFilterService } from '../filter/filter.service';
import contacts, { ContactsService } from '../../../contacts/contacts.service';
import locale, { LocaleService } from '../../../common/locale/locale.service';

class AnalysisController {
    contact: any;
    constructor(
        private analysisFilter: AnalysisFilterService, // used in view
        private contacts: ContactsService,
        private locale: LocaleService, // used in view
    ) {}
    toggleCheckbox() {
        this.contacts.selectContact(this.contact.id);
    }
}

const Analysis = {
    controller: AnalysisController,
    template: require('./item.html'),
    bindings: {
        contact: '=',
        selected: '=',
    },
};

export default angular
    .module('mpdx.reports.analysis.item.component', [analysisFilter, contacts, locale])
    .component('analysisItem', Analysis).name;
