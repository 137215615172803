import 'angular-gettext';
import { isNaN, isUndefined } from 'lodash/fp';
import accounts, { AccountsService } from '../accounts/accounts.service';

export class GoalController {
    goal: number;
    goalLabel?: string;
    goalShow: boolean;
    primary: number;
    primaryLabel: string;
    primaryPercentage: number;
    secondary: number;
    secondaryLabel: string;
    secondaryPercentage: number;
    secondaryBelow: number;
    secondaryBelowShow: boolean;
    secondaryBelowLabel: string;
    secondaryBelowPercentage: number;
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService, // used in view
    ) {}
    $onInit() {
        this.goalLabel = this.goalLabel ? this.goalLabel : this.gettextCatalog.getString('Goal');
        this.primaryLabel = this.primaryLabel ? this.primaryLabel : this.gettextCatalog.getString('Gifts Started');
        this.secondaryLabel = this.secondaryLabel ? this.secondaryLabel : this.gettextCatalog.getString('Commitments');
        this.secondaryBelowLabel = this.secondaryBelowLabel
            ? this.secondaryBelowLabel
            : this.gettextCatalog.getString('Below Goal');
        this.goalShow = isUndefined(this.goalShow) ? true : this.goalShow;
        this.secondaryBelowShow = isUndefined(this.secondaryBelowShow) ? true : this.secondaryBelowShow;
    }
    $onChanges() {
        this.primaryPercentage = this.calculatePercentage(this.primary);
        this.secondaryPercentage = this.calculatePercentage(this.secondary);
        this.secondaryBelow = this.goal - this.secondary;
        this.secondaryBelowPercentage = this.calculatePercentage(this.secondaryBelow);
    }
    calculatePercentage(amount: number): number {
        const percentage = Math.round(((amount || 0) / (this.goal || 0)) * 100);

        if (isNaN(percentage) || percentage === Infinity) {
            return 0;
        }

        return percentage;
    }
}

const Goal: ng.IComponentOptions = {
    template: require('./goal.html'),
    controller: GoalController,
    bindings: {
        goal: '<',
        goalLabel: '@',
        goalShow: '<',
        primary: '<',
        primaryLabel: '@',
        secondary: '<',
        secondaryLabel: '@',
        secondaryBelowShow: '<',
        secondaryBelowLabel: '@',
    },
};

export default angular.module('mpdx.common.goal.component', ['gettext', accounts]).component('goal', Goal).name;
