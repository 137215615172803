import 'angular-gettext';
import { defaultTo } from 'lodash/fp';
import api, { ApiService } from '../../../../common/api/api.service';
import locale, { LocaleService } from '../../../../common/locale/locale.service';
import serverConstant, { ServerConstantsService } from '../../../../common/serverConstants/serverConstants.service';

export class AddPledgeController {
    pledge: {
        amount: number;
        amount_currency: string;
        appeal: { id: string };
        contact: { id: string; pledge_currency?: string };
        expected_date: string;
        status: string;
    };
    contactName: string;
    statusDisabled: boolean;
    constructor(
        appealId: string,
        contact: any,
        pledge: any,
        private deferred: ng.IDeferred<any>,
        private $rootScope: ng.IRootScopeService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private locale: LocaleService, // used in view
        private serverConstants: ServerConstantsService, // used in view
    ) {
        this.contactName = contact.name;
        this.statusDisabled = pledge.status !== undefined;
        this.pledge = defaultTo({}, pledge);
        this.pledge.amount_currency = defaultTo(contact.pledge_currency, pledge.amount_currency);
        this.pledge.appeal = defaultTo({ id: appealId }, pledge.appeal);
        this.pledge.contact = defaultTo({ id: contact.id }, pledge.contact);
        this.pledge.status = defaultTo('not_received', pledge.status);
    }
    save() {
        const successMessage = this.gettextCatalog.getString('Successfully added commitment to appeal');
        const errorMessage = this.gettextCatalog.getString('Unable to add commitment to appeal');
        return this.api
            .post(`account_lists/${this.api.account_list_id}/pledges`, this.pledge, successMessage, errorMessage)
            .then((pledge) => {
                this.$rootScope.$emit('appeal:refresh');
                this.$rootScope.$emit('appeal:refreshCount');
                this.deferred.resolve(pledge);
                this.$scope.$hide();
            });
    }
    cancel(): void {
        this.deferred.reject();
        this.$scope.$hide();
    }
}

export default angular
    .module('mpdx.tools.appeals.show.addPledge.controller', ['gettext', api, locale, serverConstant])
    .controller('addPledgeController', AddPledgeController).name;
