import contacts, { ContactsService } from '../../../contacts/contacts.service';

export class ContactController {
    contact: any;
    constructor(private contacts: ContactsService) {}
    star(): ng.IPromise<any> {
        return this.contacts.star(this.contact).then((data) => {
            this.contact.starred_at = data.starred_at;
        });
    }
}

const Contact = {
    controller: ContactController,
    template: require('./contact.html'),
    bindings: {
        contact: '<',
    },
};

export default angular
    .module('mpdx.contacts.flows.contact.component', [contacts])
    .component('contactsFlowsContact', Contact).name;
