import accounts, { AccountsService } from '../../common/accounts/accounts.service';

export class AccountBalanceController {
    constructor(
        private accounts: AccountsService, // used in view
    ) {}
}

const AccountBalance = {
    template: require('./accountBalance.html'),
    controller: AccountBalanceController,
};

export default angular.module('mpdx.home.accountBalance', [accounts]).component('homeAccountBalance', AccountBalance)
    .name;
