const progressAppointments = {
    template: require('./appointments.html'),
    bindings: {
        analytics: '<',
        endDate: '<',
        inCoaching: '<',
        startDate: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.progress.appointments', [])
    .component('coachesShowProgressAppointments', progressAppointments).name;
