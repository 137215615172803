import announcements, { AnnouncementsService, IAnnouncement, IAnnouncementAction } from '../announcements.service';

class AnnouncementsModalController {
    announcement: IAnnouncement;
    paragraphs: string[];
    constructor(
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private announcements: AnnouncementsService,
        announcement,
    ) {
        this.announcement = announcement;
        this.paragraphs = announcement.body?.split(/\n+/) ?? [];
    }
    perform(action: IAnnouncementAction): void {
        this.announcements.perform(this.announcement, action);
        this.$scope.$hide();
    }
}

export default angular
    .module('mpdx.common.announcements.modal.controller', [announcements])
    .controller('announcementsModalController', AnnouncementsModalController).name;
