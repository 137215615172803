import { curry, times, toNumber } from 'lodash/fp';
import reduceObject from './reduceObject';

export const calculateAverages = curry((weeks: number, data: any) => {
    return reduceObject(
        (result, value, key) => {
            result[key] = value / weeks;
            return result;
        },
        {},
        data,
    );
});

export const calculateTotals = curry((totals: any, weeks: number, data: any[]) => {
    times((i) => {
        totals = reduceObject(
            (result, value, key) => {
                result[key] = value + toNumber(data[i][key]);
                return result;
            },
            {},
            totals,
        );
    }, weeks);
    return totals;
});
