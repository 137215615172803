import * as Bowser from 'bowser';
import { get, includes } from 'lodash/fp';
import { StateService, TransitionService } from '@uirouter/core';
import { UnsupportedService } from './unsupported/unsupported.service';
import { UsersService } from './common/users/users.service';
import replaceAll from './common/fp/replaceAll';

function fireAnalyticsVirtualPageView($window) {
    // Google Analytics
    $window.dataLayer.push({
        event: 'virtual-page-view',
    });
}

function changePageTitle(transition, $rootScope, $window) {
    const newState = transition.$to();
    $rootScope.pageTitle = newState.title;
    const name = newState.name.toLowerCase();
    const arr = name.split('.');
    $window.digitalData.page.category.primaryCategory = get('[0]', arr);
    $window.digitalData.page.category.subCategory1 = get('[1]', arr);
    $window.digitalData.page.category.subCategory2 = get('[2]', arr);
    $window.digitalData.page.category.subCategory3 = get('[3]', arr);
    $window.digitalData.page.pageInfo.pageName = replaceAll('.', ' : ', name);
}

export function needsAuthorization(name: string) {
    const unAuthStates = ['login', 'logout', 'unsupported', 'auth', 'handoff'];
    return !includes(name, unAuthStates);
}

// functions in params for jasmine testing
export function handleUnAuthRoutes(trans, $window, authManager, $location, block) {
    const unsupported: UnsupportedService = trans.injector().get('unsupported');
    if (unsupported.showUnsupported()) {
        return trans.router.stateService.target('unsupported');
    } else if (!authManager.isAuthenticated()) {
        // handle login re-direct and referrer
        $window.localStorage.setItem('redirect', $location.path());
        $window.localStorage.setItem('params', JSON.stringify($location.search()));
        return trans.router.stateService.target('login');
    }
    block.start();
    const users: UsersService = trans.injector().get('users');
    return users
        .getCurrent(false, true)
        .then((currentUser) => {
            $window.digitalData.user[0].profile[0].profileInfo.ssoGuid = currentUser.key_uuid;
            $window.digitalData.page.pageInfo.language = currentUser.preferences.locale;
        })
        .catch((error) => {
            if (error.redirect) {
                return trans.router.stateService.target(error.redirect);
            } else if (error.status === 401) {
                $window.localStorage.removeItem('token');
                return trans.router.stateService.target('login');
            }
        });
}

/* @ngInject*/
export default function appRun(
    $document: ng.IDocumentService,
    $q: ng.IQService,
    $location: ng.ILocationService,
    $rootScope: ng.IRootScopeService,
    $state: StateService,
    $transitions: TransitionService,
    $window: ng.IWindowService,
    $templateCache,
    authManager,
    blockUI: IBlockUIService,
) {
    let initialPage = true;
    const block = blockUI.instances.get('root');
    authManager.checkAuthOnRefresh();
    authManager.redirectWhenUnauthenticated();
    // Unauthorized route intercept
    $transitions.onStart(
        {
            to: (state) => needsAuthorization(state.name),
        },
        (trans) => handleUnAuthRoutes(trans, $window, authManager, $location, block),
    );
    $transitions.onStart({ to: 'preferences.admin' }, (trans) => {
        if (!authManager.isAuthenticated()) {
            return;
        }
        block.start();
        const users = trans.injector().get('users');
        return users
            .getCurrent(false, true)
            .then((currentUser) => {
                if (currentUser.preferences.admin) {
                    return;
                }
                return trans.router.stateService.target('unavailable');
            })
            .catch(() => {
                return trans.router.stateService.target('unavailable');
            });
    });
    $transitions.onFinish(null, (trans) => {
        changePageTitle(trans, $rootScope, $window);
        initialPage && fireAnalyticsVirtualPageView($window);
        initialPage = false;
        block.reset();
    });
    $transitions.onError(null, () => {
        block.reset();
    });
    /* eslint-disable angular/on-watch */
    $rootScope.$on('$locationChangeSuccess', () => {
        !initialPage && fireAnalyticsVirtualPageView($window);
        $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
    });
    /* eslint-enable angular/on-watch */
    $document.on('click', () => {
        $rootScope.$emit('document:click');
        $rootScope.$digest();
    });
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    $templateCache.put('angularjs-toaster/toast.html', require('./common/toast/toast.html'));
    const browserClassName = Bowser.parse(window.navigator.userAgent).browser.name.split(' ').join('-').toLowerCase();
    angular.element(document.getElementsByTagName('body')).addClass(browserClassName);
}
