import { StateService } from '@uirouter/core';
import financialAccounts, {
    FinancialAccountsService,
} from '../../../common/financialAccounts/financialAccounts.service';
import uiRouter from '@uirouter/angularjs';

export class SummaryController {
    financialAccount: any;
    financialAccountId: any;
    loading: boolean;
    constructor(
        private $state: StateService, // used in view
        private financialAccounts: FinancialAccountsService,
    ) {}
    $onInit(): void {
        this.load();
    }
    load(): ng.IPromise<any> {
        this.loading = true;
        return this.financialAccounts.get(this.financialAccountId).then((financialAccount) => {
            this.loading = false;
            this.financialAccount = financialAccount;
        });
    }
}

const Summary = {
    controller: SummaryController,
    template: require('./summary.html'),
    bindings: {
        financialAccountId: '<',
    },
};

export default angular
    .module('mpdx.reports.financialAccounts.summary.component', [uiRouter, financialAccounts])
    .component('financialAccountsSummary', Summary).name;
