import { concat, find, indexOf, reduce, reject } from 'lodash/fp';
import { v1 as uuidv1 } from 'uuid';
import contactsFlows, { FlowsService, IFlowsConfig } from '../flows.service';
import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';

interface IFlowsStatusSortableOptions {
    accept: (sourceItemHandleScope, destSortableScope) => boolean;
    containment: string;
    itemMoved: (event) => void;
    type: string;
}

interface IFlowsConfigSortableOptions {
    accept: (sourceItemHandleScope, destSortableScope) => boolean;
    containment: string;
    orderChanged: (event) => void;
}

export class SetupController {
    unusedStatuses: any[];
    config: IFlowsConfig[];
    statusSortableOptions: IFlowsStatusSortableOptions;
    configSortableOptions: IFlowsConfigSortableOptions;
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private contactsFlows: FlowsService,
        private serverConstants: ServerConstantsService,
    ) {
        this.statusSortableOptions = {
            accept: (sourceItemHandleScope, destSortableScope) => {
                return sourceItemHandleScope.itemScope.sortableScope.options.type === destSortableScope.options.type;
            },
            containment: '.columns',
            itemMoved: () => {
                this.save();
            },
            type: 'status',
        };
        this.configSortableOptions = {
            accept: (sourceItemHandleScope, destSortableScope) => {
                return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
            },
            containment: '.columns',
            orderChanged: () => {
                this.save();
            },
        };
    }
    $onInit(): void {
        this.config = angular.copy(this.contactsFlows.config);
        this.unusedStatuses = reduce(
            (result, status) => {
                const config = find((config) => (indexOf(status.id, config.statuses) as any) >= 0, this.config);
                if (!config) {
                    result.push(status.id);
                }
                return result;
            },
            [],
            this.serverConstants.data.status_hashes,
        );
    }
    addColumn(): ng.IPromise<any> {
        this.config.push({
            id: uuidv1(),
            name: this.gettextCatalog.getString('Untitled'),
            color: 'color-text',
            statuses: [],
        });
        return this.save();
    }
    removeColumn(config): ng.IPromise<any> {
        this.config = reject({ id: config.id }, this.config) as IFlowsConfig[];
        this.unusedStatuses = concat(this.unusedStatuses, config.statuses);
        return this.save();
    }
    save(): ng.IPromise<any> {
        return this.contactsFlows.saveConfig(this.config);
    }
}

const Setup = {
    controller: SetupController,
    template: require('./setup.html'),
};

export default angular
    .module('mpdx.contacts.flows.card.component', ['gettext', contactsFlows, serverConstants])
    .component('contactsFlowsSetup', Setup).name;
