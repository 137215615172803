import { curry } from 'lodash/fp';

const mode = (array: any[]): any => {
    let max = 0;
    let count = [];
    let modeElement;
    array.forEach((element) => {
        if (!count[element]) {
            count[element] = 0;
        }
        count[element]++;
        if (count[element] > max) {
            max = count[element];
            modeElement = element;
        }
    });
    return modeElement;
};

export default curry(mode);
