import { toInteger } from 'lodash/fp';
import pagination from '../pagination';
import reduceObject from '../../fp/reduceObject';

class CounterController {
    goesFrom: number;
    goesTo: number;
    meta: any;
    onChange: any;
    total: number;
    $onChanges(data) {
        this.meta = reduceObject(
            (result, value, key) => {
                result[key] = toInteger(value);
                return result;
            },
            {},
            data.meta.currentValue,
        );

        this.goesTo =
            pagination.to(this.meta.page, this.meta.per_page, this.meta.total_pages, this.meta.total_count) || 0;
        this.goesFrom = pagination.from(this.meta.page, this.meta.per_page) || 0;
        this.total = this.meta.total_count || 0;
    }
}

const Counter: ng.IComponentOptions = {
    template: require('./counter.html'),
    controller: CounterController,
    bindings: {
        meta: '<',
    },
};

export default angular.module('mpdx.pagination.counter.component', []).component('paginationCounter', Counter).name;
