import addPledge from './addPledge/add.controller';
import asked from './asked/asked.component';
import committed from './committed/committed.component';
import component from './show.component';
import editPledge from './editPledge/edit.controller';
import email from './email/email.controller';
import excluded from './excluded/excluded.component';
import given from './given/given.component';
import progressbar from './progressbar/progressbar.component';
import received from './received/received.component';
import sidebar from './sidebar/sidebar.component';
import summary from './summary/summary.component';

export default angular.module('mpdx.tools.appeals.show', [
    addPledge,
    asked,
    committed,
    component,
    editPledge,
    email,
    excluded,
    given,
    progressbar,
    received,
    sidebar,
    summary,
]).name;
