export class ReferralsController {
    tab: 'recent' | 'onHand' = 'recent';
}

const Referrals = {
    template: require('./referrals.html'),
    controller: ReferralsController,
};

export default angular.module('mpdx.home.referrals.component', []).component('homeReferrals', Referrals).name;
