import { IDeferred } from 'angular';

export class ConfirmController {
    constructor(
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private deferred: IDeferred<any>,
        private message: string, // used in view
        private title: string, // used in view
    ) {}
    no(): ng.IPromise<void> {
        this.deferred.reject();
        this.$scope.$hide();
        return this.deferred.promise;
    }
    yes(): ng.IPromise<void> {
        this.deferred.resolve();
        this.$scope.$hide();
        return this.deferred.promise;
    }
}

export default angular
    .module('mpdx.common.modal.confirm.controller', [])
    .controller('confirmController', ConfirmController).name;
