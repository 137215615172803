import 'angular-gettext';
import fixSendNewsletter, { FixSendNewsletterService } from './newsletter.service';
import modal, { ModalService } from '../../../common/modal/modal.service';
import tools, { ToolsService } from '../../tools.service';

class NewsletterController {
    watcher: () => void;
    constructor(
        $rootScope: ng.IRootScopeService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private modal: ModalService,
        private fixSendNewsletter: FixSendNewsletterService,
        private tools: ToolsService,
    ) {
        this.watcher = $rootScope.$on('accountListUpdated', () => {
            this.load();
        });
    }
    $onDestroy() {
        this.watcher();
    }
    save() {
        const message = this.gettextCatalog.getString(
            `You are updating all contacts visible on this page, setting it to the visible newsletter selection.
            Are you sure you want to do this?`,
        );
        return this.modal.confirm(message).then(() => {
            return this.fixSendNewsletter.bulkSave().then(() => {
                this.tools.getAnalytics(true);
            });
        });
    }
    load(page = null): ng.IPromise<any> {
        return this.fixSendNewsletter.load(true, page);
    }
}

const FixNewsletter: ng.IComponentOptions = {
    controller: NewsletterController,
    template: require('./newsletter.html'),
};

export default angular
    .module('mpdx.tools.fixSendNewsletter.component', ['gettext', modal, fixSendNewsletter, tools])
    .component('fixSendNewsletter', FixNewsletter).name;
