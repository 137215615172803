import accounts, { AccountsService } from '../../common/accounts/accounts.service';
import api, { ApiService } from '../../common/api/api.service';

export class MonthlyGoalController {
    watcher: () => void;
    meta: { pagination: { total_count: number } };
    constructor(
        private $rootScope: ng.IRootScopeService,
        private accounts: AccountsService, // used in view
        private api: ApiService,
    ) {}
    $onInit(): void {
        this.load();
        this.watcher = this.$rootScope.$on('accountListUpdated', () => {
            this.load();
        });
    }
    $onDestroy(): void {
        this.watcher();
    }
    load(): ng.IPromise<void> {
        return this.api
            .get({
                url: 'contacts',
                data: {
                    fields: {
                        contacts: '',
                    },
                    filter: {
                        account_list_id: this.api.account_list_id,
                        pledge_received: false,
                        status: 'Partner - Financial',
                    },
                    per_page: 0,
                },
            })
            .then((data) => {
                this.meta = data.meta;
            });
    }
    totalContacts(): number {
        return this.meta?.pagination?.total_count || 0;
    }
}

const MonthlyGoal = {
    template: require('./monthlyGoal.html'),
    controller: MonthlyGoalController,
};

export default angular.module('mpdx.home.monthlyGoal', [accounts, api]).component('homeMonthlyGoal', MonthlyGoal).name;
