import analysis from './analysis/analysis.module';
import component from './reports.component';
import contributions from './contributions/contributions.component';
import designationAccounts from './designationAccounts/designationAccounts.component';
import donations from './donations/donations.module';
import financialAccounts from './financialAccounts/financialAccounts.module';
import monthly from './monthly/monthly.component';
import sidebar from './sidebar/sidebar.component';

export default angular.module('mpdx.reports', [
    analysis,
    component,
    contributions,
    designationAccounts,
    donations,
    financialAccounts,
    monthly,
    sidebar,
]).name;
