import setup, { SetupService } from '../setup.service';

class StartController {
    saving: boolean;
    appName: string = process.env.APP_NAME;
    constructor(private setup: SetupService) {
        this.setup = setup;
    }
    $onInit(): void {
        this.setup.setPosition('start');
    }
    next(): ng.IPromise<void> {
        this.saving = true;
        return this.setup.next().then(() => {
            this.saving = false;
        });
    }
}

const Start = {
    template: require('./start.html'),
    controller: StartController,
};

export default angular.module('mpdx.setup.start.component', [setup]).component('setupStart', Start).name;
