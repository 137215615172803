import 'angular-gettext';
import { StateParams, StateService } from '@uirouter/core';
import api, { ApiService } from '../../../../common/api/api.service';
import appeals, { AppealsService } from '../../appeals.service';
import appealsShow, { AppealsShowService } from '../show.service';
import contacts, { ContactsService } from '../../../../contacts/contacts.service';
import mailchimp, { MailchimpService } from '../../../../preferences/integrations/mailchimp/mailchimp.service';
import modal, { ModalService } from '../../../../common/modal/modal.service';
import session, { SessionService } from '../../../../common/session/session.service';
import uiRouter from '@uirouter/angularjs';

export class SidebarController {
    askedCount: number;
    committedCount: number;
    excludedCount: number;
    givenCount: number;
    receivedCount: number;
    watcher: () => void;
    constructor(
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private $stateParams: StateParams,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private appeals: AppealsService, // used in view
        private api: ApiService,
        private appealsShow: AppealsShowService,
        private contacts: ContactsService, // used in view
        private mailchimp: MailchimpService, // used in view
        private modal: ModalService,
        private session: SessionService, // used in view
    ) {}
    $onInit() {
        this.watcher = this.$rootScope.$on('appeal:refreshCount', () => {
            this.refreshCounts();
        });

        return this.refreshCounts().then(() => {
            this.goToAppropriateState();
        });
    }
    $onDestroy(): void {
        this.watcher();
    }
    goToAppropriateState() {
        const newState = this.receivedCount
            ? 'tools.appeals.show.received'
            : this.committedCount
            ? 'tools.appeals.show.committed'
            : 'tools.appeals.show.asked';
        this.$state.go(newState, { appealId: this.$stateParams.appealId });
    }
    refreshCounts(status: string = null): ng.IPromise<any> {
        this.appealsShow.getAskedCount(this.$stateParams.appealId);
        switch (status) {
            case 'processed':
                return this.appealsShow.getGivenCount(this.$stateParams.appealId);
            case 'received_not_processed':
                return this.appealsShow.getReceivedCount(this.$stateParams.appealId);
            case 'not_received':
                return this.appealsShow.getCommittedCount(this.$stateParams.appealId);
            default:
                return this.refreshAllCounts();
        }
    }
    refreshAllCounts(): ng.IPromise<any> {
        return this.$q.all([
            this.appealsShow.getAskedCount(this.$stateParams.appealId),
            this.appealsShow.getGivenCount(this.$stateParams.appealId),
            this.appealsShow.getReceivedCount(this.$stateParams.appealId),
            this.appealsShow.getCommittedCount(this.$stateParams.appealId),
            this.appealsShow.getExcludedCount(this.$stateParams.appealId),
        ]);
    }
    deleteAppeal(): ng.IPromise<void> {
        const message = this.gettextCatalog.getString(
            'You are about to permanently delete this Appeal. This will remove all contacts, and delete all pledges, ' +
                'and progress towards this appeal. Are you sure you want to continue?',
        );
        return this.modal.confirm(message).then(() => {
            const errorMessage = this.gettextCatalog.getString('There was an error trying to delete the appeal.');
            return this.api
                .delete(`appeals/${this.$stateParams.appealId}`, undefined, undefined, errorMessage)
                .then(() => {
                    this.$state.go('tools.appeals');
                });
        });
    }
}

const Sidebar: ng.IComponentOptions = {
    template: require('./sidebar.html'),
    controller: SidebarController,
};

export default angular
    .module('mpdx.tools.appeals.show.sidebar.component', [
        'gettext',
        uiRouter,
        api,
        appeals,
        appealsShow,
        contacts,
        mailchimp,
        modal,
        session,
    ])
    .component('appealSidebar', Sidebar).name;
