import * as moment from 'moment';
import { contains, defaultTo, find, get, isEmpty, map, toLower, union } from 'lodash/fp';
import analytics, { AnalyticsService } from '../../../common/analytics/analytics.service';
import contacts, { ContactsService } from '../../../contacts/contacts.service';
import createPatch from '../../../common/fp/createPatch';
import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';
import tasks, { TasksService } from '../../tasks.service';
import tasksTags, { TasksTagsService } from '../../filter/tags/tags.service';

class CompleteTaskController {
    comment: any;
    status: string;
    task: any;
    taskInitialState: any;
    constructor(
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private analytics: AnalyticsService,
        private contacts: ContactsService,
        private serverConstants: ServerConstantsService,
        private tasks: TasksService,
        private tasksTags: TasksTagsService, // used in view
        task: any,
    ) {
        this.task = angular.copy(task);
        /* istanbul ignore next */
        $log.debug('task to complete', this.task);
        this.taskInitialState = angular.copy(task);
        this.task.completed = true;
        const activityType = toLower(get('activity_type', this.task));
        this.task.result = get('[0]', this.serverConstants.data.results[activityType]);
        if (this.task.activity_type === 'Appointment') {
            this.task.completed_at = defaultTo(moment().toISOString(), this.task.start_at);
        } else {
            this.task.completed_at = moment().toISOString();
        }
    }
    save() {
        return this.getPromise().then(() => {
            this.analytics.dispatch('mpdx-task-completed');
            this.$scope.$hide();
            this.handleFollowUp();
        });
    }
    getPromise(): ng.IPromise<any> {
        const taskPromise = this.createTask();
        const contactPromise = this.getContactPromise();
        return contactPromise ? this.$q.all([taskPromise, contactPromise]) : taskPromise;
    }
    createTask() {
        const patch = createPatch(this.taskInitialState, this.task);
        /* istanbul ignore next */
        this.$log.debug('task patch', patch);
        return this.tasks.save(patch, this.comment);
    }
    getContactPromise() {
        return this.status && this.showPartnerStatus()
            ? this.contacts.bulkEditFields({ status: this.status }, this.task.contacts)
            : this.$q.resolve(false);
    }
    handleFollowUp() {
        return this.task.next_action && this.task.next_action !== 'None'
            ? this.tasks.addModal({
                  activityType: this.task.next_action,
                  comments: union(defaultTo([], this.task.comments), defaultTo([], this.comment)) as any,
                  contactsList: map('id', this.task.contacts),
                  task: this.task,
              })
            : true;
    }
    showPartnerStatus() {
        return (
            this.task.contacts &&
            !isEmpty(this.task.contacts) &&
            this.task.activity_type &&
            !contains(this.task.activity_type, [
                'Pre Call Letter',
                'Reminder Letter',
                'Support Letter',
                'Thank',
                'To Do',
            ])
        );
    }
    getTranslatedActivity(value): string {
        const activity = find({ id: value }, this.serverConstants.data.activity_hashes);
        return activity ? get('value', activity) : value;
    }
}

export default angular
    .module('mpdx.tasks.modals.complete.controller', [analytics, contacts, serverConstants, tasks, tasksTags])
    .controller('completeTaskController', CompleteTaskController).name;
