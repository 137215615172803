import '@uirouter/angularjs';
import 'angular-gettext';
import { assign, findIndex, reject } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import accounts, { AccountsService } from '../../../common/accounts/accounts.service';
import api, { ApiService } from '../../../common/api/api.service';
import users, { UsersService } from '../../../common/users/users.service';

class MergePreferencesController {
    onSave: any;
    saving: boolean;
    selected_account_id: string;
    private appName: string = process.env.APP_NAME;
    constructor(
        private $state: StateService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService,
        private api: ApiService,
        private users: UsersService,
    ) {
        this.saving = false;
        this.selected_account_id = null;
    }
    merge() {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString('{{app_name}} merged your account successfully', {
            app_name: process.env.APP_NAME,
        });
        const errorMessage = this.gettextCatalog.getString("{{app_name}} couldn't merge your account", {
            app_name: process.env.APP_NAME,
        });
        return this.api
            .post(
                `account_lists/${this.api.account_list_id}/merge`,
                {
                    account_list_to_merge: { id: this.selected_account_id },
                },
                successMessage,
                errorMessage,
            )
            .then((data) => {
                this.saving = false;
                this.users.current.account_lists = reject(
                    { id: this.selected_account_id },
                    this.users.current.account_lists,
                );
                const target = findIndex({ id: this.api.account_list_id }, this.users.current.account_lists);
                if (target > -1) {
                    this.users.current.account_lists[target] = assign(this.users.current.account_lists[target], data);
                }
                this.onSave();
                return data;
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
}

const Merge = {
    controller: MergePreferencesController,
    template: require('./merge.html'),
    bindings: {
        onSave: '&',
    },
};

export default angular
    .module('mpdx.preferences.accounts.merge.component', ['gettext', 'ui.router', accounts, api, users])
    .component('mergePreferences', Merge).name;
