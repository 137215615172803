import * as moment from 'moment';
import { defaultTo } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import api, { ApiService } from '../../common/api/api.service';
import session, { SessionService } from '../../common/session/session.service';
import uiRouter from '@uirouter/angularjs';

export class ShowController {
    account: any;
    accountListId: string;
    designationAccountsData: any[];
    designationAccountsLoading: boolean;
    inCoaching: boolean;
    lastPrayerLetter: moment.Moment;
    loading: boolean;
    period: 'w' | 'm';
    watcher0: () => void;
    constructor(
        private $log: ng.ILogService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $state: StateService, // used in view
        private api: ApiService,
        private session: SessionService, // used in view
    ) {
        this.loading = false;
        this.period = 'w';
    }
    $onInit() {
        if (!this.inCoaching) {
            this.watcher0 = this.$rootScope.$on('accountListUpdated', () => {
                this.accountListId = this.api.account_list_id;
                this.load();
            });
        }
        this.load();
    }
    $onDestroy() {
        if (this.watcher0) {
            this.watcher0();
        }
    }
    load() {
        this.loading = true;
        return this.$q.all([this.getAccountList(), this.getDesignationAccounts(), this.getAnalytics()]).then(() => {
            this.loading = false;
        });
    }
    getAccountList() {
        return this.api
            .get({
                url: `account_lists/${this.accountListId}`,
                data: {
                    include:
                        'users,users.email_addresses,users.phone_numbers,' +
                        'coaches,coaches.email_addresses,coaches.phone_numbers,' +
                        'primary_appeal',
                    fields: {
                        appeal: '',
                        users: 'first_name,last_name,avatar,email_addresses,phone_numbers',
                        user_coaches: 'first_name,last_name,avatar,email_addresses,phone_numbers',
                    },
                },
                type: 'account_lists',
            })
            .then((data: any) => {
                this.loading = false;
                /* istanbul ignore next */
                this.$log.debug('coaching account list', data);
                this.account = data;
            });
    }
    getDesignationAccounts() {
        this.designationAccountsLoading = true;
        return this.api
            .get({
                url: `account_lists/${this.accountListId}/designation_accounts`,
                data: {
                    fields: {
                        designation_account: 'designation_number',
                    },
                },
            })
            .then((data) => {
                this.designationAccountsLoading = false;
                /* istanbul ignore next */
                this.$log.debug('coaching designation accounts', data);
                this.designationAccountsData = data;
            });
    }
    getAnalytics() {
        return this.api
            .get('tasks/analytics', {
                filter: {
                    account_list_id: this.accountListId,
                },
                fields: {
                    task_analytics: 'last_electronic_newsletter_completed_at,last_physical_newsletter_completed_at',
                },
            })
            .then((data) => {
                /* istanbul ignore next */
                this.$log.debug('tasks/analytics', data);
                const lastEmail = this.getDate(data.last_electronic_newsletter_completed_at);
                const lastMail = this.getDate(data.last_physical_newsletter_completed_at);
                this.lastPrayerLetter = this.hasLastLetter(data) ? this.latestDate(lastEmail, lastMail) : null;
            });
    }
    setPeriod(period: 'w' | 'm'): void {
        this.period = period;
    }
    private getDate(date): moment.Moment | null {
        return date ? moment(date) : null;
    }
    private hasLastLetter(data) {
        return data.last_physical_newsletter_completed_at || data.last_electronic_newsletter_completed_at;
    }
    private latestDate(date1, date2) {
        return date1 && date2 ? (date1.isAfter(date2) ? date1 : date2) : defaultTo(date2, date1);
    }
}

const Show = {
    controller: ShowController,
    template: require('./show.html'),
    bindings: {
        accountListId: '<',
        inCoaching: '<',
    },
};

export default angular.module('mpdx.coaches.show.component', [uiRouter, api, session]).component('coachesShow', Show)
    .name;
