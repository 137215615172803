import '@uirouter/angularjs';
import { StateService } from '@uirouter/core';
import contacts, { ContactsService } from '../contacts.service';

class ContactNewModalController {
    contact: any;
    data: any[];
    constructor(
        private gettextCatalog: ng.gettext.gettextCatalog,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private $state: StateService,
        private contacts: ContactsService,
    ) {
        this.contact = { name: '' };
    }
    go(id: number) {
        this.$state.go('contacts.show', { contactId: id });
        this.$scope.$hide();
    }
    save() {
        return this.contacts.create(this.contact).then((contact: any) => {
            if (contact) {
                this.$state.go('contacts.show', { contactId: contact.id });
                this.$scope.$hide();
            } else {
                alert(this.gettextCatalog.getString('There was an error while trying to create the contact'));
            }
        });
    }
    search() {
        return this.contacts.search(this.contact.name).then((data) => {
            this.data = data;
        });
    }
}

export default angular
    .module('mpdx.contacts.new.controller', ['ui.router', contacts])
    .controller('contactNewModalController', ContactNewModalController).name;
