import { StateParams, StateService } from '@uirouter/core';
import appealsFlows, { FlowsService } from './flows.service';
import uiRouter from '@uirouter/angularjs';
import users, { UsersService } from '../../../common/users/users.service';

export class FlowsController {
    constructor(
        private $state: StateService,
        private $stateParams: StateParams,
        private appealsFlows: FlowsService, // used in view
        private users: UsersService,
    ) {}
    changeToShowView(): void {
        this.$state.go('tools.appeals.show', {
            appealId: this.$stateParams.appealId,
        });
        this.users.saveOption('appeal_view', 'show');
    }
}

const Flows = {
    controller: FlowsController,
    template: require('./flows.html'),
};

export default angular
    .module('mpdx.tools.appeals.flows.component', [uiRouter, appealsFlows, users])
    .component('appealsFlows', Flows).name;
