import { has } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import notifications, { NotificationsService } from './notifications.service';
import session, { SessionService } from '../../common/session/session.service';

class NotificationsController {
    loading: boolean;
    watcher0: () => void;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private notifications: NotificationsService,
        private session: SessionService,
    ) {}
    $onInit(): void {
        this.load();

        this.watcher0 = this.$rootScope.$on('accountListUpdated', () => {
            this.load(true);
        });
    }
    $onDestroy(): void {
        this.watcher0();
    }
    read(userNotification): ng.IPromise<any> {
        this.session.showNotifications = false;
        if (has('notification.contact.id', userNotification)) {
            this.$state.go('contacts.show', {
                contactId: userNotification.notification.contact.id,
            });
        }
        return this.notifications.read(userNotification).then(() => {
            userNotification.read = true;
        });
    }
    load(reset = false): ng.IPromise<any> {
        if (!reset && this.notifications.data.length > 0) {
            return;
        }
        this.loading = true;
        return this.notifications.load().finally(() => {
            this.loading = false;
        });
    }
    loadMoreNotifications(): ng.IPromise<any> {
        if (this.loading) {
            return;
        }
        this.loading = true;
        return this.notifications.loadMoreNotifications().finally(() => {
            this.loading = false;
        });
    }
}

const Notifications = {
    controller: NotificationsController,
    template: require('./notifications.html'),
};

export default angular
    .module('mpdx.menu.notifications.component', ['ui.router', notifications, session])
    .component('notifications', Notifications).name;
