import { DesignationAccountsService } from './common/designationAccounts/designationAccounts.service';
import { FinancialAccountsService } from './common/financialAccounts/financialAccounts.service';
import { RewriteHandoffService } from './common/rewritehandoff/rewritehandoff.service';
import { StateService } from '@uirouter/core';

/* @ngInject*/
function logout($window, users, apioauth, cognito) {
    const impersonatorToken = $window.localStorage.getItem('impersonatorToken');
    if (users.current) {
        $window.localStorage.removeItem(`${users.current.id}_accountListId`);
    }
    if (impersonatorToken) {
        $window.localStorage.setItem('token', impersonatorToken);
        $window.localStorage.removeItem('impersonator');
        $window.localStorage.removeItem('impersonatorToken');
        $window.location.href = '/';
    } else {
        $window.localStorage.removeItem('token');
        delete $window.digitalData.user[0].profile[0].profileInfo.ssoGuid;
        if (process.env.AUTH_PROVIDER === 'COGNITO') {
            cognito.signOut();
        }
        if (process.env.AUTH_PROVIDER === 'API_OAUTH') {
            apioauth.signOut();
        }
    }
}

export default class Routes {
    static config($stateProvider, gettext: ng.gettext.gettextFunction) {
        $stateProvider
            .state({
                name: 'root',
                abstract: true,
                component: 'root',
            })
            .state({
                name: 'unsupported',
                title: gettext('Unsupported browser'),
                url: '/unsupported',
                component: 'unsupported',
            })
            .state({
                name: 'home',
                title: gettext('Dashboard'),
                url: '/',
                component: 'home',
                parent: 'root',
                resolve: {
                    handoff: /* @ngInject*/ (rewritehandoff: RewriteHandoffService, $stateParams) => {
                        return rewritehandoff.handleHandOff('/', $stateParams);
                    },
                    0: /* @ngInject*/ (serverConstants) =>
                        serverConstants.load(['activity_hashes', 'organizations_attributes']),
                },
                searchable: true,
            })
            .state({
                name: 'login',
                title: gettext('Login'),
                url: '/login',
                component: 'login',
                resolve: {
                    0: /* @ngInject*/ ($window: ng.IWindowService, $q: ng.IQService, $state: StateService) => {
                        if (
                            process.env.NODE_ENV === 'production' &&
                            $state.transition.redirectedFrom()?.to().name === 'home' &&
                            !$window.localStorage.getItem('impersonatorToken')
                        ) {
                            $window.location.href = process.env.MARKETING_SITE_URL;
                            return $q.reject();
                        }
                    },
                },
            })
            .state({
                name: 'acceptAccountListInvite',
                title: gettext('Accept Invite'),
                url: '/account_lists/{account_list_id}/accept_invite/{id}?code',
                component: 'acceptInvite',
                resolve: {
                    url: /* @ngInject*/ ($location) => $location.url($location.url().replace('#', '?')),
                },
            })
            .state({
                name: 'acceptOrganizationInvite',
                title: gettext('Accept Invite'),
                url: '/organizations/{organization_id}/accept_invite/{id}?code',
                component: 'acceptInvite',
                resolve: {
                    url: /* @ngInject*/ ($location) => $location.url($location.url().replace('#', '?')),
                },
            })
            .state({
                name: 'logout',
                title: gettext('Logout'),
                url: '/logout',
                onEnter: logout,
            })
            .state({
                name: 'coaches',
                title: gettext('Coaching Accounts'),
                url: '/coaches',
                component: 'coaches',
                parent: 'root',
            })
            .state({
                name: 'coaches.show',
                title: gettext('Coaching Account'),
                url: '/{accountListId}',
                component: 'coachesShow',
                resolve: {
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['pledge_frequency_hashes']),
                    accountListId: /* @ngInject*/ ($stateParams) => $stateParams.accountListId,
                    inCoaching: () => true,
                },
            })
            .state({
                name: 'contacts.flows',
                title: gettext('Flows'),
                url: '/flows',
                component: 'contactsFlows',
                resolve: {
                    handoff: /* @ngInject*/ (rewritehandoff: RewriteHandoffService, $stateParams) => {
                        return rewritehandoff.handleHandOff('/contacts/flows', $stateParams);
                    },
                    0: /* @ngInject*/ ($state, users) => {
                        if (users.getCurrentOptionValue('contacts_view') === 'list') {
                            $state.go('contacts');
                        }
                    },
                    1: /* @ngInject*/ (serverConstants) =>
                        serverConstants.load(['pledge_frequency_hashes', 'status_hashes']),
                    2: /* @ngInject*/ (mailchimp) => mailchimp.load(),
                    3: /* @ngInject*/ (contactsFlows) => contactsFlows.getConfig(),
                    filter: /* @ngInject*/ ($stateParams, contactFilter) => {
                        if ($stateParams.page === 1 || !contactFilter.data) {
                            return contactFilter.load().then(() => {
                                if ($stateParams.filters) {
                                    contactFilter.reset($stateParams.filters);
                                }
                            });
                        }
                        return true;
                    },
                    tag: /* @ngInject*/ (contactsTags, $stateParams) => {
                        if ($stateParams.page === 1 || contactsTags.data.length === 0) {
                            return contactsTags.load();
                        }
                        return true;
                    },
                },
            })
            .state({
                name: 'contacts.flowsSetup',
                title: gettext('Flows | Setup'),
                url: '/flows/setup',
                component: 'contactsFlowsSetup',
                resolve: {
                    handoff: /* @ngInject*/ (rewritehandoff: RewriteHandoffService, $stateParams) => {
                        return rewritehandoff.handleHandOff('/contacts/flows/setup', $stateParams);
                    },
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['status_hashes']),
                    1: /* @ngInject*/ (contactsFlows) => contactsFlows.getConfig(),
                },
            })
            .state({
                name: 'contacts.flows.show',
                title: gettext('Flows | Contact'),
                url: '/{contactId}',
                views: {
                    drawer: {
                        component: 'contactsDrawer',
                    },
                },
                resolve: {
                    handoff: /* @ngInject*/ (rewritehandoff: RewriteHandoffService, $stateParams) => {
                        return rewritehandoff.handleHandOff('/contacts/flows', $stateParams);
                    },
                },
            })
            .state({
                name: 'contacts',
                title: gettext('Contacts'),
                url: '/contacts?{page:int}&{filters:json}',
                component: 'contacts',
                params: {
                    page: 1,
                },
                parent: 'root',
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        // No return as has child states.
                        rewritehandoff.handleHandOff('/contacts', $stateParams);
                    },
                    0: /* @ngInject*/ ($stateParams, $state, users) => {
                        if (!$stateParams.contactId && users.getCurrentOptionValue('contacts_view') === 'flows') {
                            $state.go('contacts.flows');
                        }
                    },
                    1: /* @ngInject*/ (serverConstants) => serverConstants.load(['pledge_frequency_hashes']),
                    2: /* @ngInject*/ (mailchimp) => mailchimp.load(),
                    filter: /* @ngInject*/ ($stateParams, contactFilter) => {
                        if ($stateParams.page === 1 || !contactFilter.data) {
                            return contactFilter.load().then(() => {
                                if ($stateParams.filters) {
                                    contactFilter.reset($stateParams.filters);
                                }
                            });
                        }
                        return true;
                    },
                    tag: /* @ngInject*/ (contactsTags, $stateParams) => {
                        if ($stateParams.page === 1 || contactsTags.data.length === 0) {
                            return contactsTags.load();
                        }
                        return true;
                    },
                },
                searchable: true,
            })
            .state({
                name: 'contacts.show',
                title: gettext('Contact'),
                url: '/{contactId}?personId&drawer',
                component: 'contact',
                params: {
                    personId: null,
                },
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/contacts/', $stateParams);
                    },
                    0: /* @ngInject*/ (users) => users.listOrganizationAccounts(),
                    1: /* @ngInject*/ (serverConstants) =>
                        serverConstants.load([
                            'assignable_language_hashes',
                            'assignable_likely_to_give_hashes',
                            'assignable_send_newsletter_hashes',
                            'locales',
                            'pledge_currencies',
                            'pledge_frequency_hashes',
                            'preferred_contact_method_hashes',
                            'status_hashes',
                        ]),
                    2: /* @ngInject*/ ($stateParams, tasksFilter) => {
                        // for action filter
                        return tasksFilter.load().then(() => {
                            tasksFilter.reset($stateParams.filters);
                        });
                    },
                    contact: /* @ngInject*/ (contacts, $stateParams) =>
                        contacts.get($stateParams.contactId).then((data) => {
                            contacts.current = data;
                            contacts.initialState = angular.copy(data);
                            return data;
                        }),
                },
            })
            .state({
                name: 'contacts.show.addresses',
                title: gettext('Contact - Addresses'),
                url: '/addresses',
                component: 'contactAddresses',
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/contacts/?tab=ContactDetails', $stateParams);
                    },
                },
            })
            .state({
                name: 'contacts.show.details',
                title: gettext('Contact'),
                url: '/details',
                component: 'contactDetails',
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/contacts/?tab=ContactDetails', $stateParams);
                    },
                    contact: /* @ngInject*/ (contacts) => contacts.current,
                    donorAccounts: /* @ngInject*/ (contacts) => contacts.current.donor_accounts,
                },
            })
            .state({
                name: 'contacts.show.donations',
                title: gettext('Contact - Donations'),
                url: '/',
                component: 'donations',
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/contacts/?tab=Donations', $stateParams);
                    },
                    inContact: () => true,
                },
            })
            .state({
                name: 'contacts.show.notes',
                title: gettext('Contact - Notes'),
                url: '/notes',
                component: 'contactNotes',
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/contacts/?tab=Notes', $stateParams);
                    },
                },
            })
            .state({
                name: 'contacts.show.people',
                title: gettext('Contact - People'),
                url: '/people',
                component: 'contactPeople',
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/contacts/?tab=ContactDetails', $stateParams);
                    },
                },
            })
            .state({
                name: 'contacts.show.referrals',
                title: gettext('Contact - Referrals'),
                url: '/referrals',
                component: 'contactReferrals',
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/contacts/?tab=Referrals', $stateParams);
                    },
                },
            })
            .state({
                name: 'contacts.show.tasks',
                title: gettext('Contact - Tasks'),
                url: '/tasks',
                component: 'contactTasks',
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/contacts/?tab=Tasks', $stateParams);
                    },
                },
            })
            .state({
                name: 'reports',
                title: gettext('Reports'),
                url: '/reports',
                component: 'reports',
                parent: 'root',
                resolve: {
                    0: /* @ngInject*/ (designationAccounts: DesignationAccountsService) => designationAccounts.load(),
                    1: /* @ngInject*/ (financialAccounts: FinancialAccountsService) => financialAccounts.load(),
                },
            })
            .state({
                name: 'reports.analysis',
                title: gettext('Reports - Partner Giving Analysis'),
                url: '/analysis?{page:int}',
                component: 'analysis',
                params: {
                    page: 1,
                },
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        rewritehandoff.handleHandOff('/reports/partnerGivingAnalysis', $stateParams);
                    },
                    0: /* @ngInject*/ ($stateParams, analysisFilter) => {
                        return $stateParams.page === 1 || !analysisFilter.data
                            ? analysisFilter.load().then(() => {
                                  if ($stateParams.filters) {
                                      analysisFilter.reset($stateParams.filters);
                                  }
                              })
                            : true;
                    },
                    1: /* @ngInject*/ (contactsTags, $stateParams) => {
                        return $stateParams.page === 1 || contactsTags.data.length === 0 ? contactsTags.load() : true;
                    },
                    2: /* @ngInject*/ (serverConstants) => serverConstants.load(['pledge_frequency_hashes']),
                },
                searchable: true,
            })
            .state({
                name: 'reports.analysis.show',
                title: gettext('Reports - Partner Giving Analysis'),
                url: '/{contactId}',
                views: {
                    drawer: {
                        component: 'contactsDrawer',
                    },
                },
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/reports/partnerGivingAnalysis', $stateParams);
                    },
                },
            })
            .state({
                name: 'reports.designationAccounts',
                title: gettext('Reports - Designation Accounts'),
                url: '/designation_accounts',
                component: 'designationAccounts',
                searchable: true,
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/reports/designationAccounts', $stateParams);
                    },
                },
            })
            .state({
                name: 'reports.financialAccounts',
                title: gettext('Reports - Responsibility Centers'),
                url: '/financial_accounts',
                component: 'financialAccounts',
                searchable: true,
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        rewritehandoff.handleHandOff('/reports/responsibilityCenters', $stateParams);
                    },
                },
            })
            .state({
                name: 'reports.financialAccounts.show',
                title: gettext('Reports - Financial Account Summary'),
                url: '/{financialAccountId}',
                component: 'financialAccountsShow',
            })
            .state({
                name: 'reports.financialAccounts.show.entries',
                title: gettext('Reports - Financial Account Transactions'),
                url: '/entries?categoryId&startDate&endDate',
                component: 'financialAccountsEntries',
            })
            .state({
                name: 'reports.coaching',
                title: gettext('Reports - Coaching'),
                url: '/coaching',
                component: 'coachesShow',
                resolve: {
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['pledge_frequency_hashes']),
                    accountListId: /* @ngInject*/ (api) => api.account_list_id,
                    inCoaching: () => false,
                },
                searchable: true,
            })
            .state({
                name: 'reports.donations',
                title: gettext('Reports - Donations'),
                url: '/donations',
                component: 'donations',
                params: {
                    startDate: null,
                },
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/reports/donations', $stateParams);
                    },
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['pledge_currencies']),
                },
                searchable: true,
            })
            .state({
                name: 'reports.monthly',
                title: gettext('Reports - Monthly'),
                url: '/monthly',
                component: 'monthly',
                searchable: true,
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/reports/expectedMonthlyTotal', $stateParams);
                    },
                },
            })
            .state({
                name: 'reports.partner',
                title: gettext('Reports - Partner'),
                url: '/partner',
                component: 'contributions',
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/reports/partnerCurrency', $stateParams);
                    },
                    type: () => 'partner',
                    0: /* @ngInject*/ (serverConstants) =>
                        serverConstants.load(['pledge_currencies', 'pledge_frequency_hashes']),
                },
                searchable: true,
            })
            .state({
                name: 'reports.salary',
                title: gettext('Reports - Salary'),
                url: '/salary',
                component: 'contributions',
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/reports/salaryCurrency', $stateParams);
                    },
                    type: () => 'salary',
                    0: /* @ngInject*/ (serverConstants) =>
                        serverConstants.load(['pledge_currencies', 'pledge_frequency_hashes']),
                },
                searchable: true,
            })
            .state({
                name: 'preferences',
                title: gettext('Preferences'),
                url: '/preferences',
                component: 'preferences',
                parent: 'root',
            })
            .state({
                name: 'preferences.accounts',
                title: gettext('Preferences - Manage Accounts'),
                url: '/accounts',
                component: 'preferencesAccounts',
                resolve: {
                    0: /* @ngInject*/ (users) => users.getCurrent(true),
                },
                searchable: true,
            })
            .state({
                name: 'preferences.organizations',
                title: gettext('Preferences - Manage Organizations'),
                url: '/organizations',
                component: 'preferencesOrganizations',
                resolve: {
                    0: /* @ngInject*/ ($state, preferencesOrganizations) => {
                        return preferencesOrganizations.load().then(() => {
                            if (preferencesOrganizations.list.length === 0) {
                                $state.go('preferences.personal');
                            }
                        });
                    },
                },
            })
            .state({
                name: 'preferences.organizations.accountLists',
                title: gettext('Preferences - Manage Organization Account Lists'),
                url: '/account_lists',
                component: 'preferencesOrganizationsAccountLists',
            })
            .state({
                name: 'preferences.organizations.contacts',
                title: gettext('Preferences - Manage Organization Contacts'),
                url: '/contacts',
                component: 'preferencesOrganizationsContacts',
            })
            .state({
                name: 'preferences.admin',
                title: gettext('Preferences - Admin'),
                url: '/admin',
                component: 'preferencesAdmin',
            })
            .state({
                name: 'preferences.coaches',
                title: gettext('Preferences - Manage Coaches'),
                url: '/coaches',
                component: 'preferencesCoaches',
                resolve: {
                    0: /* @ngInject*/ (users) => users.getCurrent(true),
                },
                searchable: true,
            })
            .state({
                name: 'preferences.integrations',
                title: gettext('Preferences - Connect Services'),
                url: '/integrations?selectedTab',
                component: 'preferencesIntegration',
                resolve: {
                    0: /* @ngInject*/ (users) => users.listOrganizationAccounts(),
                    1: /* @ngInject*/ (serverConstants) => serverConstants.load(['organizations_attributes']),
                },
                params: {
                    selectedTab: null,
                },
                searchable: true,
            })
            .state({
                name: 'preferences.notifications',
                title: gettext('Preferences - Notifications'),
                url: '/notifications',
                component: 'preferencesNotifications',
                resolve: {
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['notification_translated_hashes']),
                },
                searchable: true,
            })
            .state({
                name: 'preferences.personal',
                title: gettext('Preferences - Personal'),
                url: '/personal',
                component: 'preferencesPersonal',
                resolve: {
                    0: /* @ngInject*/ (serverConstants) =>
                        serverConstants.load(['languages', 'locales', 'pledge_currencies', 'time_hashes']),
                },
                searchable: true,
            })
            .state({
                name: 'setup',
                title: gettext('Setup'),
                url: '/setup',
                component: 'setup',
                resolve: {
                    constants: /* @ngInject*/ (serverConstants) => serverConstants.load(['organizations_attributes']),
                },
            })
            .state({
                name: 'setup.connect',
                title: gettext('Setup - Get Connected'),
                url: '/connect',
                component: 'setupConnect',
                resolve: {
                    resolution: /* @ngInject*/ (users) => users.listOrganizationAccounts(),
                    another: /* @ngInject*/ (users) => users.getCurrent(true),
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['organizations_attributes']),
                },
            })
            .state({
                name: 'setup.account',
                title: gettext('Setup - Default Account'),
                url: '/account',
                component: 'setupAccount',
                resolve: {
                    resolution: /* @ngInject*/ (users) => users.getCurrent(true),
                },
            })
            .state({
                name: 'setup.google',
                title: gettext('Setup - Google'),
                url: '/google',
                component: 'setupGoogle',
            })
            .state({
                name: 'setup.preferences',
                title: gettext('Setup - Preferences'),
                abstract: true,
                component: 'setupPreferences',
            })
            .state({
                name: 'setup.preferences.accounts',
                title: gettext('Setup - Preferences - Merge Accounts'),
                url: '/preferences/accounts',
                component: 'setupPreferencesAccounts',
            })
            .state({
                name: 'setup.preferences.integrations',
                title: gettext('Setup - Preferences - Integrations'),
                url: '/preferences/integration',
                component: 'setupPreferencesIntegrations',
                resolve: {
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['organizations_attributes']),
                },
            })
            .state({
                name: 'setup.preferences.notifications',
                title: gettext('Setup - Preferences - Notifications'),
                url: '/preferences/notifications',
                component: 'setupPreferencesNotifications',
                resolve: {
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['notification_translated_hashes']),
                },
            })
            .state({
                name: 'setup.preferences.personal',
                title: gettext('Setup - Preferences - Personal'),
                url: '/preferences/personal',
                component: 'setupPreferencesPersonal',
                resolve: {
                    0: /* @ngInject*/ (serverConstants) =>
                        serverConstants.load(['languages', 'locales', 'pledge_currencies', 'time_hashes']),
                },
            })
            .state({
                name: 'setup.start',
                title: gettext('Setup - Get Started'),
                url: '/start',
                component: 'setupStart',
                resolve: {
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['languages']),
                },
            })
            .state({
                name: 'setup.finish',
                title: gettext('Setup - Completed'),
                url: '/finish',
                component: 'setupFinish',
            })
            .state({
                name: 'tasks',
                title: gettext('Tasks'),
                url: '/tasks?{group}&{filters:json}',
                component: 'tasks',
                parent: 'root',
                params: {
                    group: 'all',
                },
                resolve: {
                    handoff: /* @ngInject*/ ($stateParams, rewritehandoff: RewriteHandoffService) => {
                        return rewritehandoff.handleHandOff('/tasks', $stateParams);
                    },
                    filter: /* @ngInject*/ ($stateParams, tasksFilter) => {
                        return tasksFilter.load($stateParams.group).then(() => {
                            tasksFilter.reset($stateParams.filters || {});
                        });
                    },
                    group: /* @ngInject*/ ($stateParams) => $stateParams.group,
                    tag: /* @ngInject*/ (tasksTags) => tasksTags.load(),
                    0: /* @ngInject*/ (serverConstants) =>
                        serverConstants.load([
                            'assignable_send_newsletter_hashes',
                            'activity_hashes',
                            'pledge_frequency_hashes',
                            'pledge_currencies',
                            'pledge_frequency_hashes',
                            'status_hashes',
                        ]),
                },
                searchable: true,
            })
            .state({
                name: 'tools',
                title: gettext('Tools'),
                url: '/tools',
                component: 'tools',
                parent: 'root',
                params: {
                    setup: false,
                },
                searchable: true,
            })
            .state({
                name: 'tools.appeals',
                url: '/appeals',
                component: 'appeals',
                title: gettext('Appeals'),
                resolve: {
                    0: /* @ngInject*/ (contactsTags) => contactsTags.load(),
                    2: /* @ngInject*/ (contactFilter) => contactFilter.load(),
                },
                searchable: true,
            })
            .state({
                name: 'tools.appeals.show',
                url: '/{appealId}',
                component: 'appealsShow',
                resolve: {
                    0: /* @ngInject*/ ($state, $stateParams, users) => {
                        if (users.getCurrentOptionValue('appeal_view') === 'flows') {
                            $state.go('tools.appeals.flows', {
                                appealId: $stateParams.appealId,
                            });
                        }
                    },
                    1: /* @ngInject*/ (serverConstants) =>
                        serverConstants.load(['pledge_currencies', 'pledge_frequency_hashes']),
                    2: /* @ngInject*/ (mailchimp) => mailchimp.load(),
                },
                params: {
                    tour: null,
                },
            })
            .state({
                name: 'tools.appeals.show.given',
                url: '/given',
                component: 'appealGiven',
                resolve: {
                    appealId: /* @ngInject*/ ($stateParams) => $stateParams.appealId,
                },
            })
            .state({
                name: 'tools.appeals.show.received',
                url: '/received',
                component: 'appealReceived',
                resolve: {
                    appealId: /* @ngInject*/ ($stateParams) => $stateParams.appealId,
                },
            })
            .state({
                name: 'tools.appeals.show.asked',
                url: '/asked',
                component: 'appealAsked',
                resolve: {
                    appealId: /* @ngInject*/ ($stateParams) => $stateParams.appealId,
                },
            })
            .state({
                name: 'tools.appeals.show.committed',
                url: '/committed',
                component: 'appealCommitted',
                resolve: {
                    appealId: /* @ngInject*/ ($stateParams) => $stateParams.appealId,
                },
            })
            .state({
                name: 'tools.appeals.show.excluded',
                url: '/excluded',
                component: 'appealExcluded',
                resolve: {
                    appealId: /* @ngInject*/ ($stateParams) => $stateParams.appealId,
                },
            })
            .state({
                name: 'tools.appeals.flows',
                url: '/{appealId}/flows',
                component: 'appealsFlows',
                resolve: {
                    0: /* @ngInject*/ ($state, $stateParams, users) => {
                        if (users.getCurrentOptionValue('appeal_view') === 'show') {
                            $state.go('tools.appeals.show', {
                                appealId: $stateParams.appealId,
                            });
                        }
                    },
                    1: /* @ngInject*/ (serverConstants) =>
                        serverConstants.load(['pledge_currencies', 'pledge_frequency_hashes', 'status_hashes']),
                    2: /* @ngInject*/ (mailchimp) => mailchimp.load(),
                    filter: /* @ngInject*/ ($stateParams, contactFilter) => {
                        if ($stateParams.page === 1 || !contactFilter.data) {
                            return contactFilter.load().then(() => {
                                if ($stateParams.filters) {
                                    contactFilter.reset($stateParams.filters);
                                }
                            });
                        }
                        return true;
                    },
                    tag: /* @ngInject*/ (contactsTags, $stateParams) => {
                        if ($stateParams.page === 1 || contactsTags.data.length === 0) {
                            return contactsTags.load();
                        }
                        return true;
                    },
                },
            })
            .state({
                name: 'tools.appeals.flows.show',
                url: '/{contactId}',
                views: {
                    drawer: {
                        component: 'contactsDrawer',
                    },
                },
            })
            .state({
                name: 'tools.import',
                title: gettext('Tools - Import'),
                abstract: true,
                component: 'import',
                url: '/import',
            })
            .state({
                name: 'tools.import.csv',
                title: gettext('Tools - Import - CSV'),
                url: '/csv',
                component: 'importCsv',
                resolve: {
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['csv_import']),
                },
                searchable: true,
            })
            .state({
                name: 'tools.import.csv.upload',
                title: gettext('Tools - Import - CSV - Upload'),
                url: '/upload',
                component: 'importCsvUpload',
            })
            .state({
                name: 'tools.import.csv.headers',
                title: gettext('Tools - Import - CSV - Headers'),
                url: '/headers/:importId',
                component: 'importCsvHeaders',
                resolve: {
                    0: /* @ngInject*/ ($stateParams, importCsv) => importCsv.get($stateParams.importId),
                },
            })
            .state({
                name: 'tools.import.csv.values',
                title: gettext('Tools - Import - CSV - Values'),
                url: '/values/:importId',
                component: 'importCsvValues',
                resolve: {
                    0: /* @ngInject*/ ($stateParams, importCsv) => importCsv.get($stateParams.importId),
                },
            })
            .state({
                name: 'tools.import.csv.preview',
                title: gettext('Tools - Import - CSV - Preview'),
                url: '/preview/:importId',
                component: 'importCsvPreview',
                resolve: {
                    0: /* @ngInject*/ ($stateParams, importCsv) => importCsv.get($stateParams.importId),
                    1: /* @ngInject*/ (serverConstants) => serverConstants.load(['pledge_frequency_hashes']),
                },
            })
            .state({
                name: 'tools.import.google',
                title: gettext('Tools - Import - Google'),
                url: '/google',
                component: 'importGoogle',
                resolve: {
                    0: /* @ngInject*/ (contactsTags) => contactsTags.load(),
                    1: /* @ngInject*/ (google) => google.load(),
                },
                searchable: true,
            })
            .state({
                name: 'tools.import.tnt',
                title: gettext('Tools - Import - TNT'),
                url: '/tnt',
                component: 'importTnt',
                resolve: {
                    0: /* @ngInject*/ (serverConstants) => serverConstants.load(['tnt_import']),
                    1: /* @ngInject*/ (contactsTags) => contactsTags.load(),
                },
                searchable: true,
            })
            .state({
                name: 'tools.fix',
                title: gettext('Tools - Fix'),
                abstract: true,
                component: 'fix',
                url: '/fix',
            })
            .state({
                name: 'tools.fix.commitmentInfo',
                title: gettext('Tools - Fix - Commitment Info'),
                url: '/commitment-info',
                component: 'fixCommitmentInfo',
                resolve: {
                    0: /* @ngInject*/ (serverConstants, fixCommitmentInfo) =>
                        serverConstants
                            .load(['pledge_currencies', 'pledge_frequency_hashes', 'status_hashes'])
                            .then(() => fixCommitmentInfo.load()),
                },
                searchable: true,
            })
            .state({
                name: 'tools.fix.sendNewsletter',
                title: gettext('Tools - Fix - Send Newsletter'),
                url: '/send-newsletter',
                component: 'fixSendNewsletter',
                resolve: {
                    0: /* @ngInject*/ (serverConstants, fixSendNewsletter) =>
                        serverConstants
                            .load(['assignable_send_newsletter_hashes'])
                            .then(() => fixSendNewsletter.load()),
                },
                searchable: true,
            })
            .state({
                name: 'tools.fix.phoneNumbers',
                title: gettext('Tools - Fix - Phone Numbers'),
                url: '/phone-numbers',
                component: 'fixPhoneNumbers',
                resolve: {
                    0: /* @ngInject*/ (fixPhoneNumbers) => fixPhoneNumbers.load(),
                },
                searchable: true,
            })
            .state({
                name: 'tools.fix.emailAddresses',
                title: gettext('Tools - Fix - Email Addresses'),
                url: '/email-addresses',
                component: 'fixEmailAddresses',
                resolve: {
                    0: /* @ngInject*/ (fixEmailAddresses) => fixEmailAddresses.load(),
                },
                searchable: true,
            })
            .state({
                name: 'tools.fix.addresses',
                title: gettext('Tools - Fix - Addresses'),
                url: '/addresses',
                component: 'fixAddresses',
                searchable: true,
            })
            .state({
                name: 'tools.merge',
                title: gettext('Tools - Merge'),
                abstract: true,
                component: 'fix',
                url: '/merge',
            })
            .state({
                name: 'tools.merge.contacts',
                title: gettext('Tools - Merge Contacts'),
                url: '/contacts?contactId',
                component: 'mergeContacts',
                resolve: {
                    contactId: /* @ngInject*/ ($stateParams) => $stateParams.contactId,
                },
                searchable: true,
            })
            .state({
                name: 'tools.merge.people',
                title: gettext('Tools - Merge People'),
                url: '/people',
                component: 'mergePeople',
                searchable: true,
            })
            .state({
                name: 'unavailable',
                title: gettext('Unavailable'),
                url: '/unavailable',
                component: 'unavailable',
                parent: 'root',
            })
            .state({
                name: 'handoff',
                title: gettext('Handoff'),
                url: '/handoff?accessToken&userId&accountListId&path',
                component: 'handoff',
            });
    }
}
