import { map } from 'lodash/fp';
import api, { ApiService } from '../../../../common/api/api.service';
import contactsTags, { ContactsTagsService } from '../../../sidebar/filter/tags/tags.service';

export class AddController {
    tags: any[];
    constructor(
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private api: ApiService,
        private contactsTags: ContactsTagsService,
        private selectedContacts: string[],
    ) {
        this.tags = [];
    }
    save(tag: string): ng.IPromise<any> {
        const tagToAdd = tag ? [tag] : this.tags;
        if (!tagToAdd) {
            return this.$q.reject();
        }

        return this.api
            .post({
                url: 'contacts/tags/bulk',
                data: {
                    data: map(
                        (tag) => ({
                            data: {
                                type: 'tags',
                                attributes: { name: tag },
                            },
                        }),
                        tagToAdd,
                    ),
                    filter: {
                        account_list_id: this.api.account_list_id,
                        contact_ids: this.selectedContacts.join(),
                    },
                    fields: {
                        contacts: '',
                    },
                },
                doSerialization: false,
                autoParams: false,
            })
            .then(() => {
                const tag = {
                    tags: tagToAdd,
                    contactIds: this.selectedContacts,
                };
                this.$rootScope.$emit('contactTagsAdded', tag);
                this.contactsTags.addTag(tag);
                this.$scope.$hide();
            });
    }
}

export default angular
    .module('mpdxApp.contacts.bulkActions.tag.add.controller', [api, contactsTags])
    .controller('contactsBulkActionsTagAddController', AddController).name;
