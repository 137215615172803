import { StateParams } from '@uirouter/core';
import api, { ApiService } from '../../../../common/api/api.service';
import createPatch from '../../../../common/fp/createPatch';
import uiRouter from '@uirouter/angularjs';

interface ICustomRootScope extends ng.IRootScopeService {
    pageTitle: string;
}

export class SummaryController {
    appeal: any;
    appealInitialState: any;
    loading: boolean;
    watcher0: () => void;
    constructor(
        private $rootScope: ICustomRootScope,
        private $stateParams: StateParams,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
    ) {}
    $onInit(): void {
        this.watcher0 = this.$rootScope.$on('appeal:refresh', () => this.load());
        this.load();
    }
    $onDestroy(): void {
        this.watcher0();
    }
    load(): ng.IPromise<any> {
        this.loading = true;
        return this.api.get(`appeals/${this.$stateParams.appealId}`).then((appeal) => {
            this.loading = false;
            this.appeal = appeal;
            this.appealInitialState = angular.copy(appeal);
            this.$rootScope.pageTitle = `${this.gettextCatalog.getString('Appeal')} | ${this.appealInitialState.name}`;
        });
    }
    save(): ng.IPromise<any> {
        let patch: any = createPatch(this.appealInitialState, this.appeal);
        return this.api.put(`appeals/${this.$stateParams.appealId}`, patch).then((appeal) => {
            this.appeal = appeal;
            this.appealInitialState = angular.copy(appeal);
            this.$rootScope.pageTitle = `${this.gettextCatalog.getString('Appeal')} | ${this.appealInitialState.name}`;
        });
    }
}

const Summary: ng.IComponentOptions = {
    template: require('./summary.html'),
    controller: SummaryController,
};

export default angular
    .module('mpdx.tools.appeals.show.summary.component', [uiRouter, api])
    .component('appealSummary', Summary).name;
