import 'angular-gettext';
import { StateParams, StateService } from '@uirouter/core';
import contactFilter, { ContactFilterService } from './sidebar/filter/filter.service';
import help, { HelpService } from '../common/help/help.service';
import session, { SessionService } from '../common/session/session.service';
import uiRouter from '@uirouter/angularjs';

class ContactsController {
    selected: string;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private $stateParams: StateParams,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private contactFilter: ContactFilterService,
        private help: HelpService,
        private session: SessionService,
    ) {}
    $onInit() {
        if (this.help.variables().HS_CONTACTS_SUGGESTIONS?.length) {
            const suggestionsArray = [];
            this.help.variables().HS_CONTACTS_SUGGESTIONS.forEach((i) => {
                suggestionsArray.push(this.gettextCatalog.getString(i));
            });
            this.help.suggest(suggestionsArray);
        }
        this.selected = this.$stateParams.contactId;
    }
}

const Contacts = {
    controller: ContactsController,
    template: require('./contacts.html'),
};

export default angular
    .module('mpdx.contacts.component', ['gettext', uiRouter, contactFilter, help, session])
    .component('contacts', Contacts).name;
