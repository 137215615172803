import 'angular-block-ui';
import 'angular-gettext';
import * as moment from 'moment';
import { defaultTo } from 'lodash/fp';
import api, { ApiService } from '../../../common/api/api.service';
import help, { HelpService } from '../../../common/help/help.service';
import users, { UsersService } from '../../../common/users/users.service';

export class CoachesShowProgressController {
    accountListId: string;
    analytics: any;
    blockUI: IBlockUIService;
    inCoaching: boolean;
    startDate: moment.Moment;
    watcher0: () => void;
    period: 'w' | 'm';
    loading: boolean;
    showHelpButton = true;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private help: HelpService,
        private users: UsersService,
    ) {
        if (!this.help.variables().HS_COACHING_ACTIVITY?.length) {
            this.showHelpButton = false;
        }
    }
    $onInit() {
        if (!this.inCoaching) {
            this.watcher0 = this.$rootScope.$on('accountListUpdated', () => {
                this.load();
                this.users.listOrganizationAccounts(true);
            });
        }
        this.users.listOrganizationAccounts();
    }
    $onChanges() {
        this.startDate = moment().startOf(this.period === 'm' ? 'month' : 'week');
        this.load();
    }
    $onDestroy() {
        if (this.watcher0) {
            this.watcher0();
        }
    }
    nextPeriod() {
        this.startDate.add(1, this.period === 'm' ? 'month' : 'week');
        this.load();
    }
    previousPeriod() {
        this.startDate.subtract(1, this.period === 'm' ? 'month' : 'week');
        this.load();
    }
    load(): ng.IPromise<any> {
        this.analytics = null;
        this.loading = true;
        const accountListId = defaultTo(this.api.account_list_id, this.accountListId);
        return this.api
            .get(`account_lists/${accountListId}/analytics`, {
                filter: {
                    date_range: this.range(),
                },
            })
            .then((data) => {
                /* istanbul ignore next */
                this.$log.debug(`account_lists/${accountListId}/analytics`, data);
                this.analytics = data;
                this.loading = false;
            });
    }
    showHelp(): void {
        this.help.article(this.gettextCatalog.getString(this.help.variables().HS_COACHING_ACTIVITY));
    }
    displayPeriodDate(): string {
        return this.startDate.isSame(moment(), 'year')
            ? this.startDate.format('MMM D') + ' - ' + this.endDate().format('MMM D')
            : this.startDate.format('MMM D, YYYY') + ' - ' + this.endDate().format('MMM D, YYYY');
    }
    private range(): string {
        const startDate = this.startDate.locale('en').format('YYYY-MM-DD');
        const endDate = this.endDate().locale('en').format('YYYY-MM-DD');
        return `${startDate}..${endDate}`;
    }
    private endDate(): moment.Moment {
        return moment(this.startDate).endOf(this.period === 'm' ? 'month' : 'week');
    }
}

const Progress = {
    controller: CoachesShowProgressController,
    template: require('./progress.html'),
    bindings: {
        accountListId: '<',
        inCoaching: '<',
        period: '<',
    },
};

export default angular
    .module('mpdx.coaches.show.progress.component', ['blockUI', 'gettext', api, help, users])
    .component('coachesShowProgress', Progress).name;
