import accounts, { AccountsService } from '../../common/accounts/accounts.service';
import api, { ApiService } from '../../common/api/api.service';

export class AppealsController {
    appeal: any;
    watcher: () => void;
    loading: boolean;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private accounts: AccountsService, // used in view
        private api: ApiService,
    ) {}
    $onInit(): void {
        this.getPrimaryAppeal();
        this.watcher = this.$rootScope.$on('accountListUpdated', () => {
            this.getPrimaryAppeal();
        });
    }
    $onDestroy(): void {
        this.watcher();
    }
    getPrimaryAppeal(): ng.IPromise<void> {
        this.loading = true;
        return this.api
            .get(`account_lists/${this.api.account_list_id}`, {
                fields: {
                    account_lists: 'primary_appeal',
                    appeals: 'pledges_amount_processed,pledges_amount_total,name,amount',
                },
                include: 'primary_appeal',
            })
            .then(({ primary_appeal: appeal }) => {
                this.appeal = appeal;
                this.loading = false;
            });
    }
}

const Appeals = {
    template: require('./appeals.html'),
    controller: AppealsController,
};

export default angular.module('mpdx.home.appeals', [accounts, api]).component('homeAppeals', Appeals).name;
