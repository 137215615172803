import { IQService } from 'angular';
import { StateService } from '@uirouter/core';
import contactFilter, { ContactFilterService } from '../sidebar/filter/filter.service';
import contactsFlows, { FlowsService } from './flows.service';
import contactsTags, { ContactsTagsService } from '../sidebar/filter/tags/tags.service';
import uiRouter from '@uirouter/angularjs';
import users, { UsersService } from '../../common/users/users.service';

export class FlowsController {
    watcher0: any;
    constructor(
        private $q: IQService,
        private $state: StateService,
        private $rootScope: ng.IRootScopeService,
        private contactsFlows: FlowsService, // used in view
        private contactFilter: ContactFilterService,
        private contactsTags: ContactsTagsService,
        private users: UsersService,
    ) {}
    $onInit(): void {
        this.watcher0 = this.$rootScope.$on('accountListUpdated', () => {
            this.refreshFiltersAndTags();
        });
    }
    $onDestroy(): void {
        this.watcher0();
    }
    filterStarred() {
        if (this.contactFilter.params.starred) {
            delete this.contactFilter.params.starred;
        } else {
            this.contactFilter.params.starred = true;
        }
        this.$rootScope.$emit('contactsFilterChange');
    }
    changeToListView(): void {
        this.$state.go('contacts');
        this.users.saveOption('contacts_view', 'list');
    }
    private refreshFiltersAndTags(): ng.IPromise<any> {
        return this.$q.all([this.contactFilter.load(true), this.contactsTags.load()]);
    }
}

const Flows = {
    controller: FlowsController,
    template: require('./flows.html'),
};

export default angular
    .module('mpdx.contacts.flows.component', [uiRouter, contactsFlows, contactFilter, contactsTags, users])
    .component('contactsFlows', Flows).name;
