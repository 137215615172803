import { StateParams, StateService } from '@uirouter/core';
import accounts, { AccountsService } from '../common/accounts/accounts.service';
import uiRouter from '@uirouter/angularjs';

export class HandoffController {
    constructor(
        private $state: StateService,
        private $stateParams: StateParams,
        private $window: ng.IWindowService,
        private accounts: AccountsService,
    ) {}
    $onInit(): ng.IPromise<void> {
        if (
            !this.$stateParams.accessToken ||
            !this.$stateParams.accountListId ||
            !this.$stateParams.userId ||
            !this.$stateParams.path
        ) {
            this.$state.go('home');
            return;
        }
        this.$window.localStorage.setItem('token', this.$stateParams.accessToken);
        return this.accounts.swap(this.$stateParams.accountListId, this.$stateParams.userId).then(() => {
            this.redirect(this.$stateParams.path);
        });
    }
    redirect(path: string): void {
        /* istanbul ignore next */
        this.$window.location.href = path;
    }
}

const Handoff: ng.IComponentOptions = {
    template: require('./handoff.html'),
    controller: HandoffController,
};

export default angular.module('mpdx.handoff.component', [uiRouter, accounts]).component('handoff', Handoff).name;
