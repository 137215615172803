import 'angular-gettext';

export class TimeZoneService {
    data: any[];
    constructor(private gettextCatalog: ng.gettext.gettextCatalog) {
        this.data = [];

        this.load();
    }
    private load(): void {
        this.data = [
            {
                key: 'American Samoa',
                value: this.gettextCatalog.getString('(GMT-11:00) American Samoa'),
            },
            {
                key: 'International Date Line West',
                value: this.gettextCatalog.getString('(GMT-11:00) International Date Line West'),
            },
            {
                key: 'Midway Island',
                value: this.gettextCatalog.getString('(GMT-11:00) Midway Island'),
            },
            {
                key: 'Samoa',
                value: this.gettextCatalog.getString('(GMT-11:00) Samoa'),
            },
            {
                key: 'Hawaii',
                value: this.gettextCatalog.getString('(GMT-10:00) Hawaii'),
            },
            {
                key: 'Alaska',
                value: this.gettextCatalog.getString('(GMT-09:00) Alaska'),
            },
            {
                key: 'Pacific Time (US & Canada)',
                value: this.gettextCatalog.getString('(GMT-08:00) Pacific Time (US & Canada)'),
            },
            {
                key: 'Tijuana',
                value: this.gettextCatalog.getString('(GMT-08:00) Tijuana'),
            },
            {
                key: 'Arizona',
                value: this.gettextCatalog.getString('(GMT-07:00) Arizona'),
            },
            {
                key: 'Chihuahua',
                value: this.gettextCatalog.getString('(GMT-07:00) Chihuahua'),
            },
            {
                key: 'Mazatlan',
                value: this.gettextCatalog.getString('(GMT-07:00) Mazatlan'),
            },
            {
                key: 'Mountain Time (US & Canada)',
                value: this.gettextCatalog.getString('(GMT-07:00) Mountain Time (US & Canada)'),
            },
            {
                key: 'Central America',
                value: this.gettextCatalog.getString('(GMT-06:00) Central America'),
            },
            {
                key: 'Central Time (US & Canada)',
                value: this.gettextCatalog.getString('(GMT-06:00) Central Time (US & Canada)'),
            },
            {
                key: 'Guadalajara',
                value: this.gettextCatalog.getString('(GMT-06:00) Guadalajara'),
            },
            {
                key: 'Mexico City',
                value: this.gettextCatalog.getString('(GMT-06:00) Mexico City'),
            },
            {
                key: 'Monterrey',
                value: this.gettextCatalog.getString('(GMT-06:00) Monterrey'),
            },
            {
                key: 'Saskatchewan',
                value: this.gettextCatalog.getString('(GMT-06:00) Saskatchewan'),
            },
            {
                key: 'Bogota',
                value: this.gettextCatalog.getString('(GMT-05:00) Bogota'),
            },
            {
                key: 'Eastern Time (US & Canada)',
                value: this.gettextCatalog.getString('(GMT-05:00) Eastern Time (US & Canada)'),
            },
            {
                key: 'Indiana (East)',
                value: this.gettextCatalog.getString('(GMT-05:00) Indiana (East)'),
            },
            {
                key: 'Lima',
                value: this.gettextCatalog.getString('(GMT-05:00) Lima'),
            },
            {
                key: 'Quito',
                value: this.gettextCatalog.getString('(GMT-05:00) Quito'),
            },
            {
                key: 'Atlantic Time (Canada)',
                value: this.gettextCatalog.getString('(GMT-04:00) Atlantic Time (Canada)'),
            },
            {
                key: 'Caracas',
                value: this.gettextCatalog.getString('(GMT-04:00) Caracas'),
            },
            {
                key: 'Georgetown',
                value: this.gettextCatalog.getString('(GMT-04:00) Georgetown'),
            },
            {
                key: 'La Paz',
                value: this.gettextCatalog.getString('(GMT-04:00) La Paz'),
            },
            {
                key: 'Santiago',
                value: this.gettextCatalog.getString('(GMT-04:00) Santiago'),
            },
            {
                key: 'Newfoundland',
                value: this.gettextCatalog.getString('(GMT-03:30) Newfoundland'),
            },
            {
                key: 'Brasilia',
                value: this.gettextCatalog.getString('(GMT-03:00) Brasilia'),
            },
            {
                key: 'Buenos Aires',
                value: this.gettextCatalog.getString('(GMT-03:00) Buenos Aires'),
            },
            {
                key: 'Greenland',
                value: this.gettextCatalog.getString('(GMT-03:00) Greenland'),
            },
            {
                key: 'Montevideo',
                value: this.gettextCatalog.getString('(GMT-03:00) Montevideo'),
            },
            {
                key: 'Mid-Atlantic',
                value: this.gettextCatalog.getString('(GMT-02:00) Mid-Atlantic'),
            },
            {
                key: 'Azores',
                value: this.gettextCatalog.getString('(GMT-01:00) Azores'),
            },
            {
                key: 'Cape Verde Is.',
                value: this.gettextCatalog.getString('(GMT-01:00) Cape Verde Is.'),
            },
            {
                key: 'Casablanca',
                value: this.gettextCatalog.getString('(GMT+00:00) Casablanca'),
            },
            {
                key: 'Dublin',
                value: this.gettextCatalog.getString('(GMT+00:00) Dublin'),
            },
            {
                key: 'Edinburgh',
                value: this.gettextCatalog.getString('(GMT+00:00) Edinburgh'),
            },
            {
                key: 'Lisbon',
                value: this.gettextCatalog.getString('(GMT+00:00) Lisbon'),
            },
            {
                key: 'London',
                value: this.gettextCatalog.getString('(GMT+00:00) London'),
            },
            {
                key: 'Monrovia',
                value: this.gettextCatalog.getString('(GMT+00:00) Monrovia'),
            },
            {
                key: 'UTC',
                value: this.gettextCatalog.getString('(GMT+00:00) UTC'),
            },
            {
                key: 'Amsterdam',
                value: this.gettextCatalog.getString('(GMT+01:00) Amsterdam'),
            },
            {
                key: 'Belgrade',
                value: this.gettextCatalog.getString('(GMT+01:00) Belgrade'),
            },
            {
                key: 'Berlin',
                value: this.gettextCatalog.getString('(GMT+01:00) Berlin'),
            },
            {
                key: 'Bern',
                value: this.gettextCatalog.getString('(GMT+01:00) Bern'),
            },
            {
                key: 'Bratislava',
                value: this.gettextCatalog.getString('(GMT+01:00) Bratislava'),
            },
            {
                key: 'Brussels',
                value: this.gettextCatalog.getString('(GMT+01:00) Brussels'),
            },
            {
                key: 'Budapest',
                value: this.gettextCatalog.getString('(GMT+01:00) Budapest'),
            },
            {
                key: 'Copenhagen',
                value: this.gettextCatalog.getString('(GMT+01:00) Copenhagen'),
            },
            {
                key: 'Ljubljana',
                value: this.gettextCatalog.getString('(GMT+01:00) Ljubljana'),
            },
            {
                key: 'Madrid',
                value: this.gettextCatalog.getString('(GMT+01:00) Madrid'),
            },
            {
                key: 'Paris',
                value: this.gettextCatalog.getString('(GMT+01:00) Paris'),
            },
            {
                key: 'Prague',
                value: this.gettextCatalog.getString('(GMT+01:00) Prague'),
            },
            {
                key: 'Rome',
                value: this.gettextCatalog.getString('(GMT+01:00) Rome'),
            },
            {
                key: 'Sarajevo',
                value: this.gettextCatalog.getString('(GMT+01:00) Sarajevo'),
            },
            {
                key: 'Skopje',
                value: this.gettextCatalog.getString('(GMT+01:00) Skopje'),
            },
            {
                key: 'Stockholm',
                value: this.gettextCatalog.getString('(GMT+01:00) Stockholm'),
            },
            {
                key: 'Vienna',
                value: this.gettextCatalog.getString('(GMT+01:00) Vienna'),
            },
            {
                key: 'Warsaw',
                value: this.gettextCatalog.getString('(GMT+01:00) Warsaw'),
            },
            {
                key: 'West Central Africa',
                value: this.gettextCatalog.getString('(GMT+01:00) West Central Africa'),
            },
            {
                key: 'Zagreb',
                value: this.gettextCatalog.getString('(GMT+01:00) Zagreb'),
            },
            {
                key: 'Athens',
                value: this.gettextCatalog.getString('(GMT+02:00) Athens'),
            },
            {
                key: 'Bucharest',
                value: this.gettextCatalog.getString('(GMT+02:00) Bucharest'),
            },
            {
                key: 'Cairo',
                value: this.gettextCatalog.getString('(GMT+02:00) Cairo'),
            },
            {
                key: 'Harare',
                value: this.gettextCatalog.getString('(GMT+02:00) Harare'),
            },
            {
                key: 'Helsinki',
                value: this.gettextCatalog.getString('(GMT+02:00) Helsinki'),
            },
            {
                key: 'Istanbul',
                value: this.gettextCatalog.getString('(GMT+02:00) Istanbul'),
            },
            {
                key: 'Jerusalem',
                value: this.gettextCatalog.getString('(GMT+02:00) Jerusalem'),
            },
            {
                key: 'Kaliningrad',
                value: this.gettextCatalog.getString('(GMT+02:00) Kaliningrad'),
            },
            {
                key: 'Kyiv',
                value: this.gettextCatalog.getString('(GMT+02:00) Kyiv'),
            },
            {
                key: 'Pretoria',
                value: this.gettextCatalog.getString('(GMT+02:00) Pretoria'),
            },
            {
                key: 'Riga',
                value: this.gettextCatalog.getString('(GMT+02:00) Riga'),
            },
            {
                key: 'Sofia',
                value: this.gettextCatalog.getString('(GMT+02:00) Sofia'),
            },
            {
                key: 'Tallinn',
                value: this.gettextCatalog.getString('(GMT+02:00) Tallinn'),
            },
            {
                key: 'Vilnius',
                value: this.gettextCatalog.getString('(GMT+02:00) Vilnius'),
            },
            {
                key: 'Baghdad',
                value: this.gettextCatalog.getString('(GMT+03:00) Baghdad'),
            },
            {
                key: 'Kuwait',
                value: this.gettextCatalog.getString('(GMT+03:00) Kuwait'),
            },
            {
                key: 'Minsk',
                value: this.gettextCatalog.getString('(GMT+03:00) Minsk'),
            },
            {
                key: 'Moscow',
                value: this.gettextCatalog.getString('(GMT+03:00) Moscow'),
            },
            {
                key: 'Nairobi',
                value: this.gettextCatalog.getString('(GMT+03:00) Nairobi'),
            },
            {
                key: 'Riyadh',
                value: this.gettextCatalog.getString('(GMT+03:00) Riyadh'),
            },
            {
                key: 'St. Petersburg',
                value: this.gettextCatalog.getString('(GMT+03:00) St. Petersburg'),
            },
            {
                key: 'Volgograd',
                value: this.gettextCatalog.getString('(GMT+03:00) Volgograd'),
            },
            {
                key: 'Tehran',
                value: this.gettextCatalog.getString('(GMT+03:30) Tehran'),
            },
            {
                key: 'Abu Dhabi',
                value: this.gettextCatalog.getString('(GMT+04:00) Abu Dhabi'),
            },
            {
                key: 'Baku',
                value: this.gettextCatalog.getString('(GMT+04:00) Baku'),
            },
            {
                key: 'Muscat',
                value: this.gettextCatalog.getString('(GMT+04:00) Muscat'),
            },
            {
                key: 'Samara',
                value: this.gettextCatalog.getString('(GMT+04:00) Samara'),
            },
            {
                key: 'Tbilisi',
                value: this.gettextCatalog.getString('(GMT+04:00) Tbilisi'),
            },
            {
                key: 'Yerevan',
                value: this.gettextCatalog.getString('(GMT+04:00) Yerevan'),
            },
            {
                key: 'Kabul',
                value: this.gettextCatalog.getString('(GMT+04:30) Kabul'),
            },
            {
                key: 'Ekaterinburg',
                value: this.gettextCatalog.getString('(GMT+05:00) Ekaterinburg'),
            },
            {
                key: 'Islamabad',
                value: this.gettextCatalog.getString('(GMT+05:00) Islamabad'),
            },
            {
                key: 'Karachi',
                value: this.gettextCatalog.getString('(GMT+05:00) Karachi'),
            },
            {
                key: 'Tashkent',
                value: this.gettextCatalog.getString('(GMT+05:00) Tashkent'),
            },
            {
                key: 'Chennai',
                value: this.gettextCatalog.getString('(GMT+05:30) Chennai'),
            },
            {
                key: 'Kolkata',
                value: this.gettextCatalog.getString('(GMT+05:30) Kolkata'),
            },
            {
                key: 'Mumbai',
                value: this.gettextCatalog.getString('(GMT+05:30) Mumbai'),
            },
            {
                key: 'New Delhi',
                value: this.gettextCatalog.getString('(GMT+05:30) New Delhi'),
            },
            {
                key: 'Sri Jayawardenepura',
                value: this.gettextCatalog.getString('(GMT+05:30) Sri Jayawardenepura'),
            },
            {
                key: 'Kathmandu',
                value: this.gettextCatalog.getString('(GMT+05:45) Kathmandu'),
            },
            {
                key: 'Almaty',
                value: this.gettextCatalog.getString('(GMT+06:00) Almaty'),
            },
            {
                key: 'Astana',
                value: this.gettextCatalog.getString('(GMT+06:00) Astana'),
            },
            {
                key: 'Dhaka',
                value: this.gettextCatalog.getString('(GMT+06:00) Dhaka'),
            },
            {
                key: 'Urumqi',
                value: this.gettextCatalog.getString('(GMT+06:00) Urumqi'),
            },
            {
                key: 'Rangoon',
                value: this.gettextCatalog.getString('(GMT+06:30) Rangoon'),
            },
            {
                key: 'Bangkok',
                value: this.gettextCatalog.getString('(GMT+07:00) Bangkok'),
            },
            {
                key: 'Hanoi',
                value: this.gettextCatalog.getString('(GMT+07:00) Hanoi'),
            },
            {
                key: 'Jakarta',
                value: this.gettextCatalog.getString('(GMT+07:00) Jakarta'),
            },
            {
                key: 'Krasnoyarsk',
                value: this.gettextCatalog.getString('(GMT+07:00) Krasnoyarsk'),
            },
            {
                key: 'Novosibirsk',
                value: this.gettextCatalog.getString('(GMT+07:00) Novosibirsk'),
            },
            {
                key: 'Beijing',
                value: this.gettextCatalog.getString('(GMT+08:00) Beijing'),
            },
            {
                key: 'Chongqing',
                value: this.gettextCatalog.getString('(GMT+08:00) Chongqing'),
            },
            {
                key: 'Hong Kong',
                value: this.gettextCatalog.getString('(GMT+08:00) Hong Kong'),
            },
            {
                key: 'Irkutsk',
                value: this.gettextCatalog.getString('(GMT+08:00) Irkutsk'),
            },
            {
                key: 'Kuala Lumpur',
                value: this.gettextCatalog.getString('(GMT+08:00) Kuala Lumpur'),
            },
            {
                key: 'Perth',
                value: this.gettextCatalog.getString('(GMT+08:00) Perth'),
            },
            {
                key: 'Singapore',
                value: this.gettextCatalog.getString('(GMT+08:00) Singapore'),
            },
            {
                key: 'Taipei',
                value: this.gettextCatalog.getString('(GMT+08:00) Taipei'),
            },
            {
                key: 'Ulaanbaatar',
                value: this.gettextCatalog.getString('(GMT+08:00) Ulaanbaatar'),
            },
            {
                key: 'Osaka',
                value: this.gettextCatalog.getString('(GMT+09:00) Osaka'),
            },
            {
                key: 'Sapporo',
                value: this.gettextCatalog.getString('(GMT+09:00) Sapporo'),
            },
            {
                key: 'Seoul',
                value: this.gettextCatalog.getString('(GMT+09:00) Seoul'),
            },
            {
                key: 'Tokyo',
                value: this.gettextCatalog.getString('(GMT+09:00) Tokyo'),
            },
            {
                key: 'Yakutsk',
                value: this.gettextCatalog.getString('(GMT+09:00) Yakutsk'),
            },
            {
                key: 'Adelaide',
                value: this.gettextCatalog.getString('(GMT+09:30) Adelaide'),
            },
            {
                key: 'Darwin',
                value: this.gettextCatalog.getString('(GMT+09:30) Darwin'),
            },
            {
                key: 'Brisbane',
                value: this.gettextCatalog.getString('(GMT+10:00) Brisbane'),
            },
            {
                key: 'Canberra',
                value: this.gettextCatalog.getString('(GMT+10:00) Canberra'),
            },
            {
                key: 'Guam',
                value: this.gettextCatalog.getString('(GMT+10:00) Guam'),
            },
            {
                key: 'Hobart',
                value: this.gettextCatalog.getString('(GMT+10:00) Hobart'),
            },
            {
                key: 'Melbourne',
                value: this.gettextCatalog.getString('(GMT+10:00) Melbourne'),
            },
            {
                key: 'Port Moresby',
                value: this.gettextCatalog.getString('(GMT+10:00) Port Moresby'),
            },
            {
                key: 'Sydney',
                value: this.gettextCatalog.getString('(GMT+10:00) Sydney'),
            },
            {
                key: 'Vladivostok',
                value: this.gettextCatalog.getString('(GMT+10:00) Vladivostok'),
            },
            {
                key: 'Magadan',
                value: this.gettextCatalog.getString('(GMT+11:00) Magadan'),
            },
            {
                key: 'New Caledonia',
                value: this.gettextCatalog.getString('(GMT+11:00) New Caledonia'),
            },
            {
                key: 'Solomon Is.',
                value: this.gettextCatalog.getString('(GMT+11:00) Solomon Is.'),
            },
            {
                key: 'Srednekolymsk',
                value: this.gettextCatalog.getString('(GMT+11:00) Srednekolymsk'),
            },
            {
                key: 'Auckland',
                value: this.gettextCatalog.getString('(GMT+12:00) Auckland'),
            },
            {
                key: 'Fiji',
                value: this.gettextCatalog.getString('(GMT+12:00) Fiji'),
            },
            {
                key: 'Kamchatka',
                value: this.gettextCatalog.getString('(GMT+12:00) Kamchatka'),
            },
            {
                key: 'Marshall Is.',
                value: this.gettextCatalog.getString('(GMT+12:00) Marshall Is.'),
            },
            {
                key: 'Wellington',
                value: this.gettextCatalog.getString('(GMT+12:00) Wellington'),
            },
            {
                key: 'Chatham Is.',
                value: this.gettextCatalog.getString('(GMT+12:45) Chatham Is.'),
            },
            {
                key: "Nuku'alofa",
                value: this.gettextCatalog.getString("(GMT+13:00) Nuku'alofa"),
            },
            {
                key: 'Tokelau Is.',
                value: this.gettextCatalog.getString('(GMT+13:00) Tokelau Is.'),
            },
        ];
    }
}

export default angular.module('mpdx.common.timeZone.service', ['gettext']).service('timeZone', TimeZoneService).name;
