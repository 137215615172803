import 'angular-gettext';
import accounts, { AccountsService } from '../../common/accounts/accounts.service';
import api, { ApiService } from '../../common/api/api.service';
import financialAccounts, { FinancialAccountsService } from '../../common/financialAccounts/financialAccounts.service';
import uiRouter, { StateService } from '@uirouter/angularjs';

export class BalancesController {
    goals: any;
    title: string;
    watcher: () => void;
    constructor(
        private $filter: ng.IFilterService,
        private $log: ng.ILogService,
        private $state: StateService, // used in view
        private $rootScope: ng.IRootScopeService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private accounts: AccountsService, // used in view
        private financialAccounts: FinancialAccountsService,
        private api: ApiService,
    ) {}
    $onInit() {
        this.load();
        this.watcher = this.$rootScope.$on('accountListUpdated', () => {
            this.load(true);
        });
    }
    $onDestroy() {
        this.watcher();
    }
    load(reset = false): ng.IPromise<void> {
        this.goals = null;
        this.financialAccounts.load(reset);
        return this.api
            .get('reports/goal_progress', {
                filter: {
                    account_list_id: this.api.account_list_id,
                },
            })
            .then((data) => {
                /* istanbul ignore next */
                this.$log.debug('reports/goal_progress', data);
                this.goals = data;
                this.title = this.gettextCatalog.getString(
                    '{{received}} {{currency}} received/{{pledged}} {{currency}} committed of goal: {{goal}} {{currency}}.' +
                        ' Click to see outstanding financial partners.',
                    {
                        currency: this.goals.salary_currency_or_default,
                        pledged: this.$filter('currency')(this.goals.total_pledges, '', 2),
                        received: this.$filter('currency')(this.goals.received_pledges, '', 2),
                        goal: this.$filter('currency')(this.goals.monthly_goal, '', 2),
                    },
                );
            });
    }
}

const Balances = {
    controller: BalancesController,
    template: require('./balances.html'),
};

export default angular
    .module('mpdx.menu.balances.component', ['gettext', uiRouter, api, accounts, financialAccounts])
    .component('menuBalances', Balances).name;
