import { StateService } from '@uirouter/core';
import appeals, { AppealsService } from '../../../../tools/appeals/appeals.service';
import joinComma from '../../../../common/fp/joinComma';
import uiRouter from '@uirouter/angularjs';

export class NewController {
    name: string;
    constructor(
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private $state: StateService,
        private appeals: AppealsService,
        private selectedContactIds: string[],
    ) {}
    save(): ng.IPromise<void> {
        return this.appeals
            .createWithModal({
                appeal: {
                    name: this.name,
                },
                includes: {
                    ids: joinComma(this.selectedContactIds),
                },
                excludes: this.appeals.allExcludes,
                statuses: [],
            })
            .then((appeal) => {
                const total = this.selectedContactIds.length;
                const askedCount = appeal.contacts.length;
                const excludedCount = total - askedCount;

                this.$state.go('tools.appeals.show', {
                    appealId: appeal.id,
                    tour: 'start',
                });
                this.$scope.$hide();
                this.appeals.afterAddCallback(askedCount, excludedCount);
            });
    }
}

export default angular
    .module('mpdx.contacts.bulkActions.appeal.new.controller', [uiRouter, appeals])
    .controller('contactsBulkActionsAppealNewController', NewController).name;
