import { assign, get, isArray, isEmpty, isEqual, isString, some } from 'lodash/fp';
import { StateParams } from '@uirouter/core';
import contactsTags, { ContactsTagsService } from '../../../contacts/sidebar/filter/tags/tags.service';
import filters, { FiltersService } from '../../../common/filters/filters.service';

export class AnalysisFilterService {
    data: any;
    defaultParams: any;
    loading: boolean;
    params: any;
    selectedSave: string;
    wildcardSearch: string;
    constructor(
        private $log: ng.ILogService,
        private $rootScope: ng.IRootScopeService,
        private contactsTags: ContactsTagsService,
        private filters: FiltersService,
    ) {
        this.data = null;
        this.params = {};
        this.wildcardSearch = '';
        this.defaultParams = {};
        this.loading = true;
    }
    load(reset = false): ng.IPromise<any> {
        let payload = {
            data: null,
            defaultParams: null,
            params: null,
            url: 'contacts/partner_giving_analysis_filters',
        };
        if (!reset) {
            payload.data = this.data;
            payload.defaultParams = this.defaultParams;
            payload.params = this.params;
        }
        return this.filters.load(payload).then(({ data, defaultParams, params }) => {
            this.data = data;
            this.defaultParams = defaultParams;
            this.params = params;
            this.$rootScope.$emit('analysisFilterLoad');
        });
    }
    count(): number {
        return this.filters.count({
            defaultParams: this.defaultParams,
            params: this.params,
            tags: this.contactsTags,
        });
    }
    active(key: string | string[]): boolean {
        if (isString(key)) {
            return !isEqual(get(key, this.params), get(key, this.defaultParams));
        } else if (isArray(key)) {
            return some((filterKey) => this.active(filterKey), key);
        }
    }
    reset(stateParams: StateParams = null): void {
        const defaultParams = angular.copy(this.defaultParams);
        this.$rootScope.$emit('analysisSearchReset');
        this.contactsTags.reset();
        const params = this.filters.fromStrings(stateParams, this.data);
        this.params = assign(defaultParams, params);
        this.wildcardSearch = '';
        this.change();
    }
    change(filter?: any): any {
        this.selectedSave = null;
        [filter, this.params] = this.filters.handleFilterChange(filter, this.params, this.defaultParams);
        this.$rootScope.$emit('analysisFilterChange');
        this.$log.debug('analysisFilter: params', this.params);
        this.$log.debug('contactsTags: selectedTags', this.contactsTags.selectedTags);
        this.$log.debug('contactsTags: rejectedTags', this.contactsTags.rejectedTags);
        return filter;
    }
    isResettable(): boolean {
        return (
            !angular.equals(this.params, this.defaultParams) ||
            this.contactsTags.isResettable() ||
            !isEmpty(this.wildcardSearch)
        );
    }
    invertMultiselect(filter: any): void {
        const reverseName = `reverse_${filter.name}`;
        if (this.params[reverseName]) {
            delete this.params[reverseName];
        } else {
            this.params[reverseName] = true;
        }
        filter.reverse = !!this.params[reverseName];
        this.change();
    }
    buildFilterParams(accountListId?: string): any {
        return this.filters.buildFilterParams(
            this.defaultParams,
            this.params,
            this.wildcardSearch,
            this.contactsTags.selectedTags,
            this.contactsTags.rejectedTags,
            this.contactsTags.anyTags,
            accountListId,
        );
    }
    removeFilter(filter: any): void {
        const reverseName = `reverse_${filter.name}`;
        // in case the default param is an object because we don't just want the reference
        this.params[filter.name] = angular.copy(this.defaultParams[filter.name]);
        delete this.params[reverseName];
        filter.reverse = false;
    }
}

export default angular
    .module('mpdx.reports.analysis.filter.service', [contactsTags, filters])
    .service('analysisFilter', AnalysisFilterService).name;
