import * as moment from 'moment';
import api, { ApiService } from '../../common/api/api.service';
import coachingQuestionsModal, {
    CoachingQuestionsModalService,
} from '../../coachingQuestions/coachingQuestionsModal.service';

export class WeeklyActivityController {
    data: any;
    startDate: moment.Moment;
    watcher0: () => void;
    loading: boolean;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private api: ApiService,
        private coachingQuestionsModal: CoachingQuestionsModalService, // used in view
    ) {}
    $onInit() {
        this.startDate = moment().startOf('week');
        this.load();
        this.watcher0 = this.$rootScope.$on('accountListUpdated', () => {
            this.load();
        });
    }
    $onDestroy() {
        this.watcher0();
    }
    nextPeriod() {
        this.startDate.add(1, 'week');
        this.load();
    }
    previousPeriod() {
        this.startDate.subtract(1, 'week');
        this.load();
    }
    load(): ng.IPromise<void> {
        this.data = null;
        this.loading = true;
        return this.api
            .get(`account_lists/${this.api.account_list_id}/analytics`, {
                filter: {
                    date_range: this.range(),
                },
            })
            .then((data) => {
                this.data = data;
                this.loading = false;
            });
    }
    displayPeriodDate(): string {
        return this.startDate.isSame(moment(), 'year')
            ? this.startDate.format('MMM D') + ' - ' + this.endDate().format('MMM D')
            : this.startDate.format('MMM D, YYYY') + ' - ' + this.endDate().format('MMM D, YYYY');
    }
    private range(): string {
        const startDate = this.startDate.locale('en').format('YYYY-MM-DD');
        const endDate = this.endDate().locale('en').format('YYYY-MM-DD');
        return `${startDate}..${endDate}`;
    }
    private endDate(): moment.Moment {
        return moment(this.startDate).endOf('week');
    }
}

const WeeklyActivity = {
    controller: WeeklyActivityController,
    template: require('./weeklyActivity.html'),
};

export default angular
    .module('mpdx.home.weeklyActivity.component', [api, coachingQuestionsModal])
    .component('homeWeeklyActivity', WeeklyActivity).name;
