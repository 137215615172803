import * as moment from 'moment';
import { defaultTo, flatMap, get, toInteger } from 'lodash/fp';
import api, { ApiService } from '../../../common/api/api.service';
import tasks, { TasksService } from '../../../tasks/tasks.service';

export class CelebrationsController {
    people: any[];
    loading: boolean;
    watcher: () => void;
    constructor(
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private api: ApiService,
        private tasks: TasksService,
    ) {
        this.people = [];
    }
    $onInit(): void {
        this.getPeopleWithCelebrations();

        this.watcher = this.$rootScope.$on('accountListUpdated', () => {
            this.getPeopleWithCelebrations();
        });
    }
    $onDestroy(): void {
        this.watcher();
    }
    getPeopleWithCelebrations(): ng.IPromise<void> {
        this.loading = true;
        this.people = [];
        return this.$q.all([this.getPeopleWithAnniversaries(), this.getPeopleWithBirthdays()]).then(() => {
            this.loading = false;
        });
    }
    getPeopleWithAnniversaries(): ng.IPromise<void> {
        return this.api
            .get({
                url: 'reports/people_with_anniversaries',
                data: {
                    filter: {
                        account_list_id: this.api.account_list_id,
                        range: '4w',
                        end_date: moment().add(3, 'weeks').format('YYYY-MM-DD'),
                    },
                    include:
                        'people,people.facebook_accounts,people.twitter_accounts,people.email_addresses,' +
                        'people.parent_contact',
                    fields: {
                        people:
                            'first_name,last_name,anniversary_day,anniversary_month,anniversary_year,' +
                            'parent_contact,facebook_accounts,twitter_accounts,email_addresses',
                        email_addresses: 'email,primary',
                        facebook_accounts: 'username',
                        twitter_accounts: 'screen_name',
                        contacts: 'name',
                    },
                },
                overrideGetAsPost: true,
            })
            .then((data: any) => {
                this.people = [...this.people, ...flatMap((period) => period.people, data)];
            });
    }
    getPeopleWithBirthdays(): ng.IPromise<void> {
        return this.api
            .get({
                url: 'reports/people_with_birthdays',
                data: {
                    filter: {
                        account_list_id: this.api.account_list_id,
                        range: '4w',
                        end_date: moment().add(3, 'weeks').format('YYYY-MM-DD'),
                    },
                    include:
                        'people,people.facebook_accounts,people.twitter_accounts,people.email_addresses,' +
                        'people.parent_contact',
                    fields: {
                        people:
                            'first_name,last_name,birthday_day,birthday_month,birthday_year,' +
                            'parent_contact,facebook_accounts,twitter_accounts,email_addresses',
                        email_addresses: 'email,primary',
                        facebook_accounts: 'username',
                        twitter_accounts: 'screen_name',
                        contacts: '',
                    },
                },
                overrideGetAsPost: true,
            })
            .then((data: any) => {
                this.people = [...this.people, ...flatMap((period) => period.people, data)];
            });
    }
    partialDateSort(person): number {
        let sortVal = defaultTo(0, get('birthday_month', person) || get('anniversary_month', person)) * 100;
        if (sortVal > 1100 && moment().month() === 11 && moment().date() > 20) {
            // reset for dec/jan sorting
            sortVal = 0;
        }
        sortVal += defaultTo(0, get('birthday_day', person) || get('anniversary_day', person));
        return toInteger(sortVal);
    }
    addTask(contactId: string, event: ng.IAngularEvent): void {
        this.tasks.addModal({ contactsList: [contactId] });
        event.preventDefault();
        event.stopPropagation();
    }
}

const Celebrations = {
    controller: CelebrationsController,
    template: require('./celebrations.html'),
};

export default angular
    .module('mpdx.home.care.celebrations', [api, tasks])
    .component('homeCareCelebrations', Celebrations).name;
