import 'angular-gettext';
import api, { ApiService } from '../../../../common/api/api.service';
import createPatch from '../../../../common/fp/createPatch';
import locale, { LocaleService } from '../../../../common/locale/locale.service';
import serverConstant, { ServerConstantsService } from '../../../../common/serverConstants/serverConstants.service';

interface IPledge {
    id: string;
    amount: number;
    amount_currency: string;
    expected_date: string;
    status: string;
}

export class EditController {
    selectedContact: string;
    pledge: IPledge;
    initialPledge: IPledge;
    constructor(
        pledge: any,
        private deferred: ng.IDeferred<any>,
        private $rootScope: ng.IRootScopeService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private api: ApiService,
        private locale: LocaleService, // used in view
        private serverConstants: ServerConstantsService, // used in view
    ) {
        this.selectedContact = pledge.contact.name;
        this.pledge = {
            id: pledge.id,
            amount: pledge.amount,
            amount_currency: pledge.amount_currency,
            expected_date: pledge.expected_date,
            status: pledge.status,
        };
        this.initialPledge = { ...pledge };
    }
    save(): ng.IPromise<any> {
        const successMessage = this.gettextCatalog.getString('Successfully edited commitment');
        const errorMessage = this.gettextCatalog.getString('Unable to edit commitment');
        const patch = createPatch(this.initialPledge, this.pledge);

        return this.api
            .put(
                `account_lists/${this.api.account_list_id}/pledges/${this.pledge.id}`,
                patch,
                successMessage,
                errorMessage,
            )
            .then((pledge) => {
                this.$rootScope.$emit('appeal:refresh');
                this.$rootScope.$emit('appeal:refreshCount');
                this.deferred.resolve(pledge);
                this.$scope.$hide();
            });
    }
    cancel(): void {
        this.deferred.reject();
        this.$scope.$hide();
    }
}

export default angular
    .module('mpdx.tools.appeals.show.editPledge.controller', ['gettext', api, locale, serverConstant])
    .controller('editPledgeController', EditController).name;
