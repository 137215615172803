import { IPromise } from 'angular';
import coachingQuestionsController from './coachingQuestions.controller';
import modal, { ModalService } from '../common/modal/modal.service';

export interface IQuestion {
    created_at: Date;
    position: number;
    prompt: string;
    required: boolean;
    response_options: string[] | null;
    updated_at: Date;
    updated_in_db_at: Date;
    id: string;
}
export class CoachingQuestionsModalService {
    constructor(private modal: ModalService) {}
    launch(): IPromise<any> {
        const params = {
            template: require('./coachingQuestions.html'),
            controller: 'coachingQuestions',
        };
        return this.modal.open(params);
    }
}

export default angular
    .module('mpdx.coachingQuestionsModal.service', [modal, coachingQuestionsController])
    .service('coachingQuestionsModal', CoachingQuestionsModalService).name;
