import 'angular-gettext';
import * as moment from 'moment';
import * as Upload from 'ng-file-upload';
import { get } from 'lodash/fp';
import alerts, { AlertsService } from '../../../common/alerts/alerts.service';
import help, { HelpService } from '../../../common/help/help.service';
import locale, { LocaleService } from '../../../common/locale/locale.service';
import modal, { ModalService } from '../../../common/modal/modal.service';
import preferencesOrganization, { PreferencesOrganizationService } from './organization.service';
import serverConstants, { ServerConstantsService } from '../../../common/serverConstants/serverConstants.service';
import users, { UsersService } from '../../../common/users/users.service';

export class OrganizationIntegrationPreferencesController {
    moment: any;
    importing: boolean;
    page: string;
    password: string;
    saving: boolean;
    syncingId: string;
    selected: any;
    selectedKey: string;
    username: string;
    showHelpButton = true;
    appName: string = process.env.APP_NAME;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $window: ng.IWindowService,
        private gettextCatalog: ng.gettext.gettextCatalog,
        private Upload: ng.angularFileUpload.IUploadService,
        private alerts: AlertsService,
        private help: HelpService,
        private modal: ModalService,
        private preferencesOrganization: PreferencesOrganizationService,
        private serverConstants: ServerConstantsService,
        private users: UsersService,
        private locale: LocaleService, // used in view
    ) {
        this.moment = moment;
        this.saving = false;
        this.page = 'org_list';
        this.selected = null;
        this.username = null;
        this.password = null;
        if (!this.help.variables().HS_SETTINGS_SERVICES_FIND_ORGANIZATION?.length) {
            this.showHelpButton = false;
        }
    }
    $onInit(): void {
        this.users.listOrganizationAccounts();
        this.$rootScope.$on('accountListUpdated', () => {
            this.users.listOrganizationAccounts(true);
        });
    }
    save(): ng.IPromise<any> {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString('Preferences saved successfully');
        const errorMessage = this.gettextCatalog.getString('Unable to save preferences');
        return this.preferencesOrganization
            .save(successMessage, errorMessage)
            .then(() => {
                this.users.listOrganizationAccounts(true);
                this.saving = false;
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
    disconnect(id): ng.IPromise<any> {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString('{{app_name}} removed your organization integration', {
            app_name: process.env.APP_NAME,
        });
        const errorMessage = this.gettextCatalog.getString(
            "{{app_name}} couldn't save your configuration changes for that organization",
            {
                app_name: process.env.APP_NAME,
            },
        );
        return this.preferencesOrganization
            .disconnect(id, successMessage, errorMessage)
            .then(() => {
                this.saving = false;
                this.users.listOrganizationAccounts(true);
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
    sync(id): ng.IPromise<any> {
        const successMessage = this.gettextCatalog.getString(
            '{{app_name}} started syncing your organization account. ' +
                'This will occur in the background over the next 24-hours.',
            {
                app_name: process.env.APP_NAME,
            },
        );
        const errorMessage = this.gettextCatalog.getString("{{app_name}} couldn't sync your organization account", {
            app_name: process.env.APP_NAME,
        });
        this.syncingId = id;
        return this.preferencesOrganization
            .sync(id, successMessage, errorMessage)
            .then(() => {
                this.syncingId = undefined;
            })
            .catch((err) => {
                this.syncingId = undefined;
                throw err;
            });
    }
    createAccount(): ng.IPromise<any> {
        this.saving = true;
        return this.preferencesOrganization
            .createAccount(this.username, this.password, this.selectedKey)
            .then(() => {
                this.saving = false;
                this.users.listOrganizationAccounts(true);
                this.revert();
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
    updateAccount(): ng.IPromise<any> {
        this.saving = true;
        const successMessage = this.gettextCatalog.getString('{{app_name}} updated your organization account', {
            app_name: process.env.APP_NAME,
        });
        const errorMessage = this.gettextCatalog.getString('Unable to update your organization account');
        return this.preferencesOrganization
            .updateAccount(this.username, this.password, this.selected.id, successMessage, errorMessage)
            .then(() => {
                this.saving = false;
                this.users.listOrganizationAccounts(true);
                this.revert();
            })
            .catch((err) => {
                this.saving = false;
                throw err;
            });
    }
    editAccount(account): void {
        this.page = 'edit_account';
        this.selected = account;
        this.username = account.username;
    }
    revert(): void {
        this.selectedKey = null;
        this.selected = null;
        this.username = '';
        this.password = '';
        this.page = 'org_list';
    }
    select(): void {
        this.username = '';
        this.password = '';
        this.selected = get(this.selectedKey, this.serverConstants.data.organizations_attributes);
    }
    showOrganizationHelp(): void {
        this.help.article(this.gettextCatalog.getString(this.help.variables().HS_SETTINGS_SERVICES_FIND_ORGANIZATION));
    }
    import(account): ng.IPromise<any> {
        this.importing = true;
        return this.preferencesOrganization
            .import(account)
            .then(() => {
                account.showTntDataSync = false;
                this.modal.info(
                    this.gettextCatalog.getString(
                        'File successfully uploaded. The import to {{ name }} will begin in the background.',
                        { name: account.organization.name },
                        null,
                    ),
                    'success',
                );
                account.file = null;
                this.importing = false;
            })
            .catch((err) => {
                this.alerts.addAlert(this.gettextCatalog.getString('File upload failed.'), 'danger');
                account.file = null;
                this.importing = false;
                throw err;
            });
    }
    authenticate(organizationId): void {
        this.saving = true;
        this.$window.location.href = this.preferencesOrganization.oAuth(organizationId);
    }
}

const Organization = {
    controller: OrganizationIntegrationPreferencesController,
    template: require('./organization.html'),
};

export default angular
    .module('mpdx.preferences.organization.component', [
        'gettext',
        Upload,
        alerts,
        help,
        modal,
        preferencesOrganization,
        serverConstants,
        users,
        locale,
    ])
    .component('organizationIntegrationPreferences', Organization).name;
