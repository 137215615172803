import '@uirouter/angularjs';
import 'angular-gettext';
import { any, assign, concat, defaultTo, find, map, reduce } from 'lodash/fp';
import { StateService } from '@uirouter/core';
import { v1 as uuidv1 } from 'uuid';
import api, { ApiService } from '../../common/api/api.service';
import serverConstants, { ServerConstantsService } from '../../common/serverConstants/serverConstants.service';
import users, { UsersService } from '../../common/users/users.service';

class NotificationPreferencesController {
    loading: boolean;
    notificationPreferences: any;
    onSave: any;
    setup: boolean;
    watcher: any;
    appName: string = process.env.APP_NAME;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $state: StateService,
        private gettext: ng.gettext.gettextFunction,
        private api: ApiService,
        private serverConstants: ServerConstantsService,
        private users: UsersService,
    ) {
        this.notificationPreferences = [];
        this.loading = false;
    }
    $onInit(): void {
        this.watcher = this.$rootScope.$on('accountListUpdated', () => {
            this.load();
        });
        this.load();
    }
    $onDestroy(): void {
        this.watcher();
    }
    load(): ng.IPromise<void> {
        this.loading = true;
        const errorMessage = this.gettext('Unable to load notification preferences');
        return this.api
            .get(
                `account_lists/${this.api.account_list_id}/notification_preferences?include=notification_type`,
                undefined,
                undefined,
                errorMessage,
            )
            .then((data) => {
                this.notificationPreferences = reduce(
                    (result, notification) => {
                        const notificationPreference = defaultTo(
                            {},
                            find((object) => {
                                return object.notification_type.id === notification.key;
                            }, data),
                        );
                        return concat(result, {
                            id: defaultTo(uuidv1(), notificationPreference.id),
                            notification_type: { id: notification.key },
                            title: notification.value,
                            app: this.defaultIfInSetup(notificationPreference, 'app'),
                            email: this.defaultIfInSetup(notificationPreference, 'email'),
                            task: this.defaultIfInSetup(notificationPreference, 'task'),
                            override: true,
                        });
                    },
                    [],
                    this.serverConstants.data.notification_translated_hashes,
                );
                this.loading = false;
            })
            .catch((ex) => {
                this.loading = false;
                throw ex;
            });
    }
    private defaultIfInSetup(notificationPreference: any, type: string): boolean {
        return this.setup
            ? defaultTo(true, notificationPreference[type])
            : defaultTo(false, notificationPreference[type]);
    }
    save(): ng.IPromise<void> {
        this.loading = true;
        const successMessage = this.gettext('Notifications saved successfully');
        const errorMessage = this.gettext('Unable to save changes');
        return this.api
            .post({
                url: `account_lists/${this.api.account_list_id}/notification_preferences/bulk`,
                data: this.notificationPreferences,
                type: 'notification_preferences',
                fields: {
                    notification_preferences: '',
                },
                errorMessage: errorMessage,
                successMessage: successMessage,
            })
            .then(() => {
                this.onSave();
                this.loading = false;
            })
            .catch((ex) => {
                this.loading = false;
                throw ex;
            });
    }
    next(): ng.IPromise<void> {
        this.users.currentOptions.setup_position.value = '';
        return this.users.setOption(this.users.currentOptions.setup_position).then(() => {
            this.$state.go('home');
        });
    }
    selectAll(type: string): void {
        this.notificationPreferences = map(
            (notification) =>
                assign(notification, {
                    [type]: !this.isAny(type),
                }),
            this.notificationPreferences,
        );
    }
    isAny(type: string): boolean {
        return any((notification) => notification[type], this.notificationPreferences);
    }
}

const Notifications = {
    controller: NotificationPreferencesController,
    template: require('./notifications.html'),
    bindings: {
        onSave: '&',
        setup: '<',
    },
};

export default angular
    .module('mpdx.preferences.notifications.component', ['gettext', 'ui.router', api, serverConstants, users])
    .component('preferencesNotifications', Notifications).name;
