import isNilOrEmpty from '../../common/fp/isNilOrEmpty';
import session, { SessionService } from '../../common/session/session.service';
import users, { UsersService } from '../../common/users/users.service';

export class ImpersonationBarController {
    impersonated: string;
    impersonator: string;
    constructor(
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService,
        private session: SessionService,
        private users: UsersService,
    ) {}
    $onInit() {
        this.impersonator = this.$window.localStorage.getItem('impersonator');
        this.session.navImpersonation = !!this.impersonator;
        this.$timeout(() => {
            this.impersonated =
                isNilOrEmpty(this.impersonator) || isNilOrEmpty(this.users.current)
                    ? null
                    : `${this.users.current.first_name}`;
        }, 500);
    }
}

const ImpersonationBar = {
    template: require('./impersonationBar.html'),
    controller: ImpersonationBarController,
};

export default angular
    .module('mpdx.menu.impersonationBar', [session, users])
    .component('impersonationBar', ImpersonationBar).name;
