import { get, map, upperCase } from 'lodash/fp';
import { split } from '../fp/strings';

function abbreviate() {
    return (name) => {
        const names = split(' ', name);
        return map((name) => upperCase(get('[0]', name)), names).join('.');
    };
}

export default angular.module('mpdx.common.isEmpty', []).filter('abbreviate', abbreviate).name;
