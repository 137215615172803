import * as moment from 'moment';
import { curry, reduce } from 'lodash/fp';

export const daysLate = curry((date: string) => {
    return moment().diff(moment(date), 'days') || 0;
});

export const weeksFromNow = curry((key: string, data: any[]) => {
    const weekStart = moment().startOf('week');
    return reduce(
        (result, value) => {
            const isBefore = moment(value[key]).isBefore(weekStart);
            return isBefore ? result + 1 : result;
        },
        0,
        data,
    );
});
