import * as moment from 'moment';
import accounts, { AccountsService } from '../../../../common/accounts/accounts.service';
import contacts, { ContactsService } from '../../../contacts.service';
import contactsTags, { ContactsTagsService } from '../../../sidebar/filter/tags/tags.service';
import locale, { LocaleService } from '../../../../common/locale/locale.service';
import serverConstants, { ServerConstantsService } from '../../../../common/serverConstants/serverConstants.service';

export class EditController {
    languages: any[];
    models: any;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private $scope: mgcrea.ngStrap.modal.IModalScope,
        private accounts: AccountsService, // used in view
        private contactsTags: ContactsTagsService, // used in view
        private locale: LocaleService, // used in view
        private serverConstants: ServerConstantsService, // used in view
        private contacts: ContactsService,
        private selectedContacts: any[],
    ) {
        this.models = {};
    }
    save(): ng.IPromise<any> {
        if (this.models.starred_at) {
            this.models.starred_at = this.models.starred_at === 'starred' ? moment().format() : null;
        }
        return this.contacts.bulkEditFields(this.models, this.selectedContacts).then(() => {
            this.$scope.$hide();
            this.$rootScope.$emit('contactCreated');
        });
    }
}

export default angular
    .module('mpdx.contacts.bulkActions.contact.edit.controller', [
        accounts,
        contacts,
        contactsTags,
        locale,
        serverConstants,
    ])
    .controller('contactsBulkActionsContactEditController', EditController).name;
